<template>
    <div class="maskBox" v-show="showMask">
        <ApplyJob 
            v-if="showType=='applyJob'"
            @hideMask="hideMask"
            :objData="objData"
        />
    </div>
</template>
<script>
import ApplyJob from './maskModule/applyJob.vue'
import { watch,reactive,toRefs} from "vue";
export default {
    components: {
        ApplyJob,
    },
    props:['showMask','maskData'],
    setup(props, context) {
        const {showMask,maskData} = props
        const {showType,objData} = maskData||{}
        const state = reactive({
            objData,
            showType,
            showMask,
            objData,
        })
        watch(props,()=>{
            const {showMask,maskData} = props
            const {showType,objData} = maskData||{}
            state.showType= showType
            state.objData = objData
        })
        function hideMask(){
            context.emit('hideMask')
        }
        const refData = toRefs(state)
        return {
            hideMask,
            ...refData
        }
    }
}
</script>
<style scoped>
    .maskBox{
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.5);
        top: 0;
        left: 0;
        z-index: 12;
    }
</style>