<template>
  <div>
    <div class="inputUl">
        <div class="inputList border">
            <input type="text" :placeholder="`*${$t('main.userName')}`" class="inputVal" v-model="userName">
        </div>
        <div class="inputList border marginLeft">
            <input type="text" :placeholder="`*${$t('main.userMobil')}`"  class="inputVal" v-model="userMobil">
        </div>
        <div class="inputList border marginLeft">
            <input type="text" :placeholder="`*${$t('main.userEmail')}`"  class="inputVal"  v-model="userEmail">
        </div>
    </div>
    <div  class="inputUl">
        <div class="inputList border">
            <input type="text" :placeholder="`*${$t('main.userCompanyName')}`"  class="inputVal" v-model="userCompanyName">
        </div>
        <div class="inputList border marginLeft">
            <input type="text" :placeholder="`*${$t('main.userAddress')}`"  class="inputVal" v-model="userCompanyAddress">
        </div>
    </div>
    <textarea name="" id="" cols="30" rows="10" :placeholder="`*${$t('main.onlineMessage')}`" class="border inputVal" v-model="userCompanyDeatils"></textarea>
    <div class="submit">
        <span class="submitBtn border " @click="submiFormData">{{$t('main.submit')}}</span>
    </div>

    <div class="position-fixed bottom-0 right-0 p-3" style="z-index: 5; right: 0; bottom: 0;">
        <div id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="4000">
            <div class="toast-header">
                <strong class="mr-auto">{{$t('main.promptTxt')}}</strong>
                <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="toast-body">{{toastTxt}}</div>
        </div>
    </div>

    <div class="tips" v-if="tips.flag">
        {{ tips.message }}
    </div>

  </div>
</template>
<script>
    import {POST} from '../../http/api'
    import {reactive,toRefs} from 'vue';
    import {useI18n} from 'vue-i18n'

    export default {
        components: {},
        props: {},
        setup(props, context) {
            const {t} = useI18n()
            const state = reactive({
                userName:null,
                userMobil:null,
                userEmail:null,
                userCompanyName:null,
                userCompanyAddress:null,
                userCompanyDeatils:null,
                toastTxt:null,
                tips: {
                    message: '',
                    flag: !1
                }
            })
            function submiFormData(){
                const {userName,userMobil,userEmail,userCompanyName,userCompanyAddress} = state
                let strEmailRegex = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
                if(!userName){
                    toastFun(t('main.nameCannot'))
                    return
                }
                if(!userMobil||userMobil.length!=11){
                    toastFun(t('main.wrongMobile'))
                    return
                }
                if(!strEmailRegex.test(userEmail)){
                    toastFun(t('main.wrongEmail'))
                    return
                }
                if(!userCompanyName){
                    toastFun(t('main.companyCannot'))
                    return
                }
                if(!userCompanyAddress){
                    toastFun(t('main.companyAddressCannot'))
                    return
                }
                POST({"leave_message_info": {
                    "name":  state.userName, 
                    "phone_number":  state.userMobil, 
                    "email":  state.userEmail, 
                    "company_name": state.userCompanyName, 
                    "company_address":  state.userCompanyAddress, 
                    "content":  state.userCompanyDeatils 
                }},'submit_leave').then((msg)=>{
                    if(msg.code==20000){
                        state.tips.message = t('main.submitSuccess')
                        state.tips.flag = !0
                        setTimeout(() => {
                            state.tips.message = ''
                            state.tips.flag = !1
                        }, 2000)
                        // toastFun(t('main.submitSuccess'))
                    } else alert(msg.code)
                })
            }
            function toastFun(val){
                state.toastTxt=val
                $('.toast').toast('show')
            }
            const refData = toRefs(state)
            return {
                submiFormData,
                ...refData
            }
        }
    }
</script>
<style scoped>
    input::input-placeholder{
        color:#8B8B8B;
    }
    .toast{
        position:fixed;
        top: 6rem;
        right: 1rem;
    }
    .submit{
        margin-top: 2.5rem;
        text-align: center;
    }
    .submitBtn{
        cursor: pointer;
        padding: .75rem 2.875rem;
        border-radius:.625rem ;
        border-color: #0089D2 !important;
        color: #0089D2;
        font-size: .875rem;
    }
    .inputUl{
        display: flex;
        flex-direction: row;
        margin-bottom: 1.875rem;
    }
    .marginLeft{
        margin-left: 2.375rem;
    }
    .inputList{
        flex: 1;
        border-radius: .4375rem;
    }
    .inputVal{
        width: 100%;
        border: none;
        background: #F8F8F8;
        border-radius: .4375rem;
        padding: 1.25rem;
        font-size: .875rem;
    }
    .tips {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 8px 10px;
        color: aliceblue;
        background-color: rgba(0, 0, 0, .75);
        z-index: 99;
        border-radius: 10px;
    }
</style>