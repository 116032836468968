<template>
    <div style="margin-top: 5rem;">
        <img :src="appBanner" alt="" srcset="" class="appBanner">
        <div class="divView" :style="{ background: '#fff' }">
            <div class="childerNavBox">
                <ChilderNav :obj="childerNav" backColor="#F7F9FA" @parentFun="navFun" :navIndex="childeIndex" />
            </div>
            <div class="appliBox">
                <div class="appList" v-for="(item, index) of appliContentList" :key="index">
                    <TitleModel :titleTxt="item.name" />
                    <div class="listUl" @mouseenter="moveApplyFun(index)" @mouseleave="moutBoxFun()">
                        <div class="listLeftImg" v-if="index % 2 == 0">
                            <img :src="imgUrl + item.cover" alt="" class="listImg">
                        </div>
                        <div class="listBox listPaddLeft">
                            <div class="listTitleBox">
                                <p class="listTitle">{{ $t("main.appScenario") }}<i class="listActive"></i></p>
                            </div>
                            <p class="listContent size16" v-html="item.content"></p>
                        </div>
                        <div class="listBox mL" v-if="index % 2 != 0">
                            <img :src="imgUrl + item.cover" alt="" class="listImg">
                        </div>
                    </div>
                </div>
            </div>
            <div class="moreBtn">
                <Botton :titleTxt="`${$t('main.learnMore')}`" @more="moreFun" />
            </div>
        </div>
    </div>
</template>
<script>
import ChilderNav from '../module/childerNav.vue'
import TitleModel from '../module/titleModel.vue'
import Botton from '../module/botton.vue'
import { POST, ldImg } from '../../http/api'
import router from '@/router/index.js'
import { useRoute } from "vue-router";
import { onMounted, watch, reactive, toRefs } from "vue"
import editorCss from '@/utils/editorCss'
import general from "@/utils/general"

export default {
    components: {
        ChilderNav, TitleModel, Botton
    },
    props: ['navData'],
    setup(props, context) {
        const $route = useRoute();
        const state = reactive({
            appliContentList: [],
            childeIndex: $route.query.twoIndex || 0,
            topPx: 0,
            childerNav: [],
            appBanner: null,
            imgUrl: ldImg(),
        })

        watch(() => props.navData, (n, o) => {
            if(n.menu_data) chiderFun(n.menu_data)
        })

        onMounted(() => {
            if(props.navData) chiderFun(props.navData.menu_data)
            $('body,html').animate({
                scrollTop: 0
            })
        })

        watch(() => $route.query, (n, o) => {
            if(n.twoIndex) {
                const ulHeight = $(".childerBox").height() + 20
                state.childeIndex = n.twoIndex
                navFun(state.childeIndex, ulHeight)
            }
        })

        function moreFun() {
            context.emit('viewParent', { index: 4 })
            router.push('/CooperationLd');
        }

        // 点击上标，切换下标内容
        function chiderFun(data) {
            // const num = window.sessionStorage.getItem('topNavIndex') == 0 ? 0 : window.sessionStorage.getItem('topNavIndex') - 1 ?? 0
            const childerIndex = window.sessionStorage.getItem('childerNavIndex') || 0
            if (data && data[2]) {
                state.childerNav = data[2].module_data
                state.childeIndex = childerIndex
                state.appliContentList = state.childerNav.map(item => {
                    item['content'] = general.isMobileFn() ? item['mobile_content'] ? editorCss.format(item['mobile_content']) : '' : item['content'] ? editorCss.format(item['content']) : ''
                    return item
                })
                state.appBanner = state.imgUrl + state.appliContentList[0].banner
            }
        }

        // 跳转到对应的高度上
        function navFun(index, scorHeight) {
            state.topPx = $('.appList').eq(index).offset().top
            $('body,html').animate({
                scrollTop: state.topPx - scorHeight - $('.childerNavBox').height()
            })
        }
        function moveApplyFun(index) {
            const demo = $('.appList').eq(index)
            demo.find('.listActive').stop().animate({ width: '100%' })
            demo.find('.listImg').addClass('imgHover')
            demo.siblings().find('.listActive').stop().animate({ width: '50%' })
            demo.siblings().find('.listImg').removeClass('imgHover')
        }
        function moutBoxFun() {
            $('.appList').find('.listActive').stop().animate({ width: '50%' })
            $('.appList').find('.listImg').stop().removeClass('imgHover')
        }
        const refData = toRefs(state)
        return {
            navFun, moveApplyFun, moutBoxFun, moreFun,
            ...refData,
        }
    }
}
</script>
<style scoped>
.appBanner {
    width: 100%;
    display: block;
}

.appliBox {
    padding-top: 4.12rem;
}

.appList {
    margin-bottom: 5.06rem;
}

.listUl {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: center;
    background: #F7F9FA;
    margin-top: 2.93rem;
}

.listBox {
    width: 44.9375rem;
    height: 27.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.listImg {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 1s ease-in-out;
}

.listTitleBox {
    margin-bottom: 4rem;
}

.listTitle {
    color: #4C4948;
    font-size: 1.25rem;
    position: relative;
    padding-bottom: 0;
}

.listContent {
    color: #3F3F3F;
    line-height: 2rem;
}

.listContent>>>p {
    word-break: break-all;
}

.listPaddLeft {
    padding: 0 6rem;
}

.listActive {
    position: absolute;
    width: 50%;
    height: 2px;
    background: #0089D2;
    left: 0;
    transform: scale(1, .5);
    bottom: -1.12rem;
}

.moreBtn {
    display: flex;
    justify-content: center;
}

.listLeftImg {
    width: 44.9375rem;
    height: 27.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgHover {
    transform: scale(1.2, 1.2);
}

/* .childerNavBox {
    position: fixed;
    left: 15rem;
    right: 15rem;
    z-index: 99;
} */
</style>