<template>
  <div style="margin-top: 5rem;min-height: 300px;">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li v-for="(item, index) of bannerList" :key="index" data-target="#carouselExampleIndicators"
          :data-slide-to="index" :class="[{ active: index == 0 }]"></li>
      </ol>
      <div class="carousel-inner">
        <div v-for="(item, index) of bannerList" :key="index" :class="['carousel-item', { active: index == 0 }]" @click.stop="onOpenBannerFn(item.url)">
          <img
            :src="(imgUrl + `${isMobileData ? item.mobile_banner_image : item.banner_image_path}`)"
            class="d-block w-100"
            :alt="item.banner_name">
        </div>
      </div>
      <button class="carousel-control-prev bannerBtn" type="button" data-target="#carouselExampleIndicators"
        data-slide="prev">
        <img src="../assets/image/bannerLeft.png" class="bannerIcon" alt="...">
      </button>
      <button class="carousel-control-next bannerBtn" type="button" data-target="#carouselExampleIndicators"
        data-slide="next">
        <img src="../assets/image/bannerRight.png" class="bannerIcon" alt="...">
      </button>
    </div>
    <div class="indexContentBox">
      <TitleModel :titleTxt="`${$t('main.technology')}`" />
      <ul class="containerUl">
        <li v-for="(item, index) of applicationLi" :key="index" :class="['applicationList', { marginSty: index == 1 }]"
          :style="{ backgroundImage: 'url(' + imgUrl + `${isMobileData ? item.mobile_cover : item.cover}` + ')', }"
          @mouseenter="moveLiFun(index)" @mouseleave="hideModal()" @click="clickOpenWep(index)">
          <div class="applicationContent">
            <img :src="imgUrl + item.icon" class="applicationIcon" alt="...">
            <p class="applicationTxt">{{ item.name }}</p>
          </div>
          <span class="pplicationModal modalTop"></span>
          <span class="pplicationModal modalBottom"></span>
        </li>
      </ul>
      <TitleModel :titleTxt="`${$t('main.newTxt')}`" />
      <ul class="containerUl">
        <li v-for="(item, index) of newsList" :key="index" :class="[
          'newsList', 'newsMobileStyle',
          { marginSty: index == 1 },
          { 'border-bottom': isMobileData && index == 0 },
          { 'paddTop': index == 0 },
        ]" @click="newDetails(item.id)">
          <img :src="(imgUrl + item.cover)" class="newsIcon" alt="...">
          <div class="detailsBox">
            <p class="details">{{ item.title }}</p>
            <p class="datailsTime">{{ time(item.create_time) }}</p>
          </div>
        </li>
        <li class="newsRightList">
          <dl v-for="(item, index) of newData" :key="index" :class="[
            'newsRightDl',
            { oneList: index == 0 }, { 'border-top': isMobileData },
            { 'border-bottom': !isMobileData && index == 0 },
          ]" @click="newDetails(item.id)">
            <dt class="newsTitle">{{ item.title }}</dt>
            <dd class="newsTime">{{ time(item.create_time) }}</dd>
            <dd class="newsDetails">{{ item.introduction }}</dd>
          </dl>
        </li>
      </ul>
      <div class="moreNews">
        <Botton :titleTxt="`${$t('main.moreNews')}`" @more="moreFun" />
      </div>
    </div>
  </div>
</template>

<script>
import { POST, ldImg, changeDateFormat } from '../http/api'
import TitleModel from './module/titleModel.vue'
import { onMounted, reactive, toRefs } from 'vue';
import Botton from './module/botton.vue'
import router from '@/router/index.js'
export default {
  name: 'LdIndex',
  components: {
    TitleModel, Botton
  },
  setup(props, context) {
    const state = reactive({
      bannerList: [],
      imgUrl: ldImg(),
      applicationLi: [],
      newsList: [],
      newData: [],
      isMobileData: false,
    })
    onMounted(() => {
      $('body,html').animate({
          scrollTop: 0
      })
      load()
    })
    function load() {
      const languageType = window.sessionStorage.getItem('language') || 'zh'
      POST({ "language": languageType == 'zh' ? 0 : 1 }, 'get_home').then((msg) => {
        if (msg.code == 20000) {
          const { banner_info, home_info } = msg.data
          const { product_info, news_info } = home_info
          state.bannerList = banner_info ? banner_info : ''
          state.applicationLi = product_info
          state.newsList = [news_info[0], news_info[1]]
          state.newData = [news_info[2], news_info[3]]
          winResize()
        } else {
          alert(msg.code)
        }
      })
      $(window).resize(function () {
        winResize()
      })
    }
    function winResize() {
      state.isMobileData = $(window).width() > 870 ? false : true
    }
    function moveLiFun(index) {
      $('.applicationList').eq(index).find('.pplicationModal').stop().animate({ height: '0' })
      $('.applicationList').eq(index).siblings().find('.pplicationModal').stop().animate({ height: '50%' })
    }
    function hideModal() {
      $('.applicationList').find('.pplicationModal').stop().animate({ height: '50%' })
    }
    function clickOpenWep(index) {
      context.emit('viewParent', { index: index })
      switch (index) {
        case 0:
          router.push('/SensorIndex');
          break;
        case 1:
          router.push('/SolutionIndex');
          break;
        case 2:
          router.push('/ApplyIndex');
          break;
      }
    }
    function moreFun() {
      context.emit('viewParent', { index: 4 })
      router.push('/LdInformation');
    }
    function newDetails(id) {
      router.push({ name: 'NewDetails', query: { id: id } })
    }
    function time(time) {
      return changeDateFormat(time)
    }
    const refData = toRefs(state)

    // banner跳转传感器产品
    const onOpenBannerFn = e => {
      const dom = document.createElement('a')
      dom.href = e
      dom.target = '_target'
      document.body.appendChild(dom)
      dom.click()
      document.body.removeChild(dom)
      // window.sessionStorage.setItem("topNavIndex", 2)
      // router.push({ name: 'ProductDetails', query: { sensor_name: e }} )
    }
    return {
      moveLiFun, hideModal, moreFun, clickOpenWep, newDetails, time, onOpenBannerFn,
      ...refData,
    }
  },
}
</script>
<style scoped>
.datailsTime {
  display: none;
}

.newsIcon {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
}

.carousel-indicators {
  margin-bottom: 2rem;
}

.bannerIcon {
  width: 3.31rem;
  height: 6.18rem;
}

.indexContentBox {
  padding: 5.06rem 15rem 0 15rem;
}

.containerUl {
  display: flex;
  flex-direction: row;
  margin-top: 3.06rem;
  margin-bottom: 4.87rem;
}

.applicationList {
  width: 28.75rem;
  height: 31.25rem;
  background-size: cover;
  background-position: center;
  border-radius: .31rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.pplicationModal {
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  background: rgba(0, 0, 0, .1);
}

.modalTop {
  top: 0;
}

.modalBottom {
  bottom: 0;
}

.newsList {
  width: 28.75rem;
  border-radius: 0;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  transition: .5s;
}

.newsList:hover {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, .5)
}

.newsRightList {
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.newsRightDl:hover .newsTitle {
  color: #0089D2;
}

.detailsBox {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, .2);
  padding: .56rem 1.25rem;
  left: 0;
  bottom: 0;
  border-radius: 0 0 5px 5px;
}

.details {
  color: #fff;
  font-size: 1.12rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.applicationIcon {
  width: 3.56rem;
  height: 3.56rem;
}

.marginSty {
  margin: 0 1.81rem;
}

.applicationTxt {
  color: #FFFFFF;
  font-size: 1.25rem;
  font-family: 'OPPOSans-B';
}

.applicationContent {
  flex: 1;
  text-align: center;
}

.newsTitle {
  color: #737373;
  /* font-family: 'OPPOSans-M'; */
  font-size: 1.12rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.newsTime {
  color: #898989;
  font-family: 'OPPOSans-R';
  -webkit-transform-origin-x: 0;
  -webkit-transform-origin-y: 0;
  font-size: .75rem;
  transform: scale(.75);
  margin-top: .6875rem;
  margin-bottom: .2rem;
}

.newsDetails {
  color: #898989;
  font-family: 'OPPOSans-R';
  font-size: .87rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: 1.6875rem;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /*! autoprefixer: on */
  word-break: break-all;
  -webkit-transform-origin-x: 0;
  -webkit-transform-origin-y: 0;
  transform: scale(.87);
}

.oneList {
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}

.moreNews {
  display: flex;
  justify-content: center;
  margin-bottom: 4.93rem;
}
</style>
