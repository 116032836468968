<template>
  <div style="margin-top: 5rem;">
    <img :src="ldbanner" alt="" srcset="" class="joinUsBanner">
    <div class="divView mobile-bg" :style="{background: '#FFFFFF'}">
        <div class="joinUsBox">
            <TitleModel
                :titleTxt="`${$t('main.joinUs')}`"
            />
        </div>
        <div class="joinUsUl">
            <div class="joinUsList" v-for="(item,index) of propaganda" :key="index" >
                <div v-for="(items,indexs) of item.childeList" :key="indexs" 
                    :class="['listBox',{hideMargin:indexs==item.childeList.length-1}]">
                    <img class="listImg" :src="items.cover" :alt="items.info">
                    <p class="listTitle">{{items.info}}</p>
                </div>
            </div>
        </div>

        <div class="pc-show">
            <div class="joinUsSearch">
                <div class="searchBox">
                    <img src="../../assets/image/saveIcon.jpg" alt="" srcset="" class="saveIcon">
                    <input type="text" :placeholder="`${$t('main.forAjob')}`" v-model="searchVal" class="searchInput size16">
                    <span class="searchBtn" @click="getJobInfo">{{$t('main.searchTxt')}}</span>
                </div>
            </div>

            <div class="postBox">
                <div class="postTypeLeftBox">
                    <div class="typeTopBox">
                        <span class="topLi topTitle size16">{{$t('main.screening')}}</span>
                        <span class="topLi delSelBtn size16" @click="delCheck">{{$t('main.removeTxt')}}</span>
                    </div>
                    <div class="typeUl" v-for="(item,index) of typeListObj" :key="index">
                        <h1 class="ulTitle">{{item.name}}</h1>
                        <div class="ulList" v-for="(items,indexs) of item.child" :key="indexs"
                            @click="selectFun(index,indexs,items.id)"
                        >
                            <span class="radion border"></span>
                            <p class="listTxt">{{items.name}}</p>
                        </div>
                    </div>
                </div>
                <div class="typeContentBox">
                    <h1 class="contentTitle">{{$t('main.newJob')}}</h1>
                    <div class="contentList" v-for="(item,index) of contentList" :key="index" ref=""
                        @click="openDetails(index)"
                    >
                        <h1 class="contentListTitle">{{item.fields.jobs_name}} </h1>
                        <p class="contentAdd size16">{{item.fields.address_names.toString().replace(/,/g," | ")}}</p>
                        <p class="contentTxt">{{item.fields.content}}</p>
                    </div>
                </div>
            </div>

            <PagePlugin
                :total="total"
                :pagesize="pageSize"
                :currentPage="pageNum"
                @change-page="changePage"
            />
        </div>
        <div :class="['mobile-show', { monolayer: showMobileNav }]" style="display: none">
            <div class="mobile-join-header row aCenter jSb">
                <div :class="['fixedClass', { fixeRadio: showMobileNav }]" style="display: none">
                    <div class="rowMobile">
                        <img
                        :src="showMobileNav ? require('../../assets/image/icon_nav_close.png') : require('../../assets/image/screen.png')"
                        class="screen"
                        @click="openMobileNav">
                    </div>
                </div>
                <div class="screenInptBody row aCenter">
                    <img src="../../assets/image/saveIcon.jpg" alt="" srcset="" class="screenSear">
                    <input type="text" placeholder="搜索" class="screenInpt" />
                </div>
                <span class="screenSearch">搜索</span>
            </div>
            <!-- 筛选弹窗 -->
            <div class="mobileChoose">
                <div class="mobile-choose-header row aCenter jSb">
                    <img src="../../assets/image/icon_nav_close.png" class="screen" @click.stop="onCloseMobileChooseFn">
                    <span class="screenSearch" @click.stop="onCloseMobileChooseFn">清除</span>
                </div>
                <div class="mobile-choose-body column">
                    <div class="choose-body-lump" v-for="(item, key) in typeListObj" :key="key">
                        <p class="choose-title">{{ item.name }}</p>
                        <div class="row aCenter wrap">
                            <button
                                type="button"
                                :class="['btn', i.choose ? 'choosebtn' : 'noChoosebtn']"
                                @click="selectFun(key,k,i.id, item)"
                                v-for="(i, k) in item.child"
                                :key="k">{{ i.name }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 工作岗位 -->
            <div class="jobs" v-if="contentList && contentList.length > 0">
                <div class="jobs-list column" v-for="(item, key) in contentList" :key="key" @click="openDetails(key)">
                    <p class="jobs-title">{{ item.fields.jobs_name }}</p>
                    <p class="contentAdd size16">{{item.fields.address_names.toString().replace(/,/g," | ")}}</p>
                    <p class="contentTxt">{{item.fields.content}}</p>
                </div>
            </div>
            <div v-else class="jobs-empty">暂无岗位</div>
            
        </div>

    </div>
    <Mask
        :showMask="showMask"
        :maskData="maskData"
        @hideMask="hideMask"
    />
  </div>
</template>
<script>
import TitleModel from '../module/titleModel.vue'
import PagePlugin from '../module/PagePlugin.vue'
import Mask from '../module/mask.vue'
import {POST, ldImg} from '../../http/api'
import { onMounted,reactive,toRefs,watch, nextTick} from "vue";
import {useI18n} from 'vue-i18n'

export default {
    components: {
        TitleModel,PagePlugin,Mask
    },
    props:['navData'],
    setup(props, context) {
        const {t} = useI18n()
        const state = reactive({
            typeListObj:[],
            contentList:[],
            typeIndex: 0,
            formId:'',
            typeId: '', 
            addressId:'', 
            searchVal:'',
            total:null,
            pageSize:4,
            pageNum:1,
            maskData:{
                showType:null,
                objData:null,
            },
            hrEmail:null,
            showMask:false,
            propaganda: new Array(),        // 加入我们 - 上半部分静态
            showMobileNav: !1,
            ldbanner: null
        })
        onMounted(()=>{
            nextTick(() => {
                state.hrEmail = props?.navData?.company_info[0]?.company_hr_email
            })
            $('body,html').animate({
                scrollTop: 0
            })
            load()
            bannerImg()
        })
        watch(() =>props.navData,(nweProps, oldProps) => {
            const {company_info} = props.navData
            const {company_hr_email} = company_info[0]
            state.hrEmail = company_hr_email
            bannerImg()
        })
        function openMobileNav() {
            $('.mobileChoose').slideToggle()
            state.showMobileNav = !state.showMobileNav
        }
        function load(){
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            POST({ "language" : languageType == 'zh' ? 0 : 1 }, 'get_jobType').then((msg)=>{
                if(msg.code==20000){
                    const {job_type} = msg.data
                    if(job_type && job_type.length > 0) {
                        job_type?.map(item => {
                            if(item['child'] && item['child'].length > 0) {
                                item['child']?.map(i => i['choose'] = !1)
                            }
                        })
                    }
                    state.typeListObj = job_type
                    getJobInfo(languageType)
                } else alert(msg.code)
            })

            // 获取加入我们 - 上部分静态数据
            POST({ "language": languageType == 'zh' ? 0 : 1 },'get_jobs_propaganda').then(r => {
                const m = r.data.propaganda_list.map(item => {
                    item['fields'].cover = ldImg() + item['fields'].cover
                    return item['fields']
                })
                state.propaganda = [{ childeList: [m[0], m[1]] }, { childeList: [m[2], m[3], m[4]] }]
            }).catch(e => {
                console.log(e)
            })
        }

        function bannerImg(){
            if(!props.navData) return
            const {join_us_banner} =props.navData
            state.ldbanner = ldImg()+join_us_banner
        }
        function getJobInfo(){
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            POST({
                "language": languageType=='zh'?0:1,
                "list_query": {
                    "page": state.pageNum, 
                    "limit": state.pageSize, 
                    "form_ids":state.formId,
                    "type_ids": state.typeId, 
                    "address_ids": state.addressId, 
                    "name": state.searchVal,
                } 
            },'get_jobInfo').then((msg)=>{
                if(msg.code==20000){
                    const {job_info_data,total} = msg.data
                    state.contentList = job_info_data
                    state.total = total
                }else{
                    alert(msg.code)
                }
            })
        }
        // 切换筛选
        function selectFun(index, indexs, id, m = null) {
            id = String(id)
            switch (index) {
                case 0:
                    state.formId = id
                    break;
                case 1:
                    state.typeId = id
                    break;
                case 2:
                    state.addressId = id
                    break;
            }
            state.pageNum = 1
            getJobInfo()
            m?.child.map((item, key) => {
                item['choose'] = !1
                if(key == indexs) item['choose'] = !0
            })
            const demo =  $('.typeUl').eq(index).find('.ulList')
            demo.eq(indexs).find('.radion').addClass('radionActive')
            demo.eq(indexs).siblings().find('.radion').removeClass('radionActive')  
            onCloseMobileChooseFn()
        }

        // 关闭筛选弹窗
        const onCloseMobileChooseFn = () => {
            $('.mobileChoose').slideUp()
            state.showMobileNav = !state.showMobileNav
        }

        function delCheck(){
            state.formId = '';
            state.typeId = '';
            state.addressId = '';
            state.pageNum = 1;
            state.searchVal='';
            $('.radion').removeClass('radionActive')
            getJobInfo()
        }
        function changePage(currentPage){
            state.pageNum = currentPage
            load()
        }
        function openDetails(index){
            state.maskData.showType='applyJob'
            state.showMask=true
            state.maskData.objData= state.contentList[index]
            state.maskData.objData.hrEmail=state.hrEmail
        }
        function hideMask(){
            state.maskData.showType=null
            state.showMask=false
        }
        const refData = toRefs(state)
        return {
            selectFun,delCheck,changePage,openDetails,hideMask,getJobInfo,openMobileNav,onCloseMobileChooseFn,
            ...refData,
        }
    }
}
</script>
<style scoped>
    .postBox{
        padding-top: 2.75rem;
        border-top: 1px solid #DEDEDE;
        margin-top:3.5rem ;
        display: flex;
        flex-direction:row;
    }
    .contentList{
        margin-top: 1.25rem;
        padding:1.28rem 2.56rem;
        cursor: pointer;
        transition: .5s;
        border-radius: .62rem;
    }
    .contentList:hover{
        box-shadow:0px 3px 10px rgba(0,0,0,.3)
    }
    .contentListTitle{
        color: #242424;
        font-size: 1.25rem;
    }
    .contentAdd{
        color:#4C4C4C;
        margin-top: 1.25rem;
    }
    .contentTxt{
        text-align: justify;
        color:#595959;
        font-size: .87rem;
        margin-top: 1.25rem;
    }
    .typeContentBox{
        flex: 1;
        padding-left: 2.56rem;
    }
    .contentTitle{
        padding-left: 1.93rem;
        color:#343434;
        font-size: 1.87rem;
    }
    .typeUl{
        margin-top: 1.87rem;
        cursor: pointer;
    }
    .ulTitle{
        color:#242424;
        font-size:1.25rem ;
    }
    .radionActive{
        border: none !important;
        background: #0089D2;
        /* background-image: url('../../assets/image/selIcon.jpg'); */
        /* background-size:100% 100%; */
        /* background-position:center; */
    }
    .radion{
        width: 1rem;
        height: 1rem;
    }
    .listTxt{
        color:#4C4C4C;
        font-size: 0.87rem;
        margin-left: .56rem;
    }
    .ulList{
        display: flex;
        flex-direction:row;
        align-items:center;
        margin-top: 1.37rem;
    }
    .postTypeLeftBox{
        width: 13.88rem;
        padding-right: 2.62rem;
        border-right: 1px solid #DEDEDE;
    }
    .typeTopBox{
        display: flex;
        flex-direction:row;
        align-items: center;
        padding-bottom: 1.18rem;
        border-bottom: 1px solid #DEDEDE;
    }
    .topTitle{
        flex: 1;
    }
    .delSelBtn{
        cursor: pointer;
    }
    .topLi{
        color:#4C4C4C;
    }
    .joinUsBanner{
        width: 100%;
        display: block;
    }
    .joinUsBox{
        padding: 5rem 0 3.06rem 0;
    }
    .joinUsList{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2.18rem;
        transition: .5s;
    }
    .listBox:hover .listTitle{
        color: #0089D2;
    }
    .listBox{
        flex: 1;
        height: 23rem;
        background-size:100% 100%;
        background-position:center;
        position: relative;
        margin-right: 1.87rem;
    }
    .listImg{
        width: 100%;
        height: 100%;
        display: block;
    }
    .listTitle{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, .5);
        color:#fff;
        font-size: 1.25rem;
        padding: 1.125rem ;
    }
    .hideMargin{
        margin-right: 0;
    }
    .joinUsSearch{
        margin-top: 4.62rem;
    }
    .searchBox{
        width:37.56rem;
        padding-left: 1.43rem;
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, .3);
        margin: auto;
        display: flex;
        flex-direction:row;
        align-items: center;
    }
    .saveIcon{
        width: 1rem;
        height: 1rem;
    }
    .searchInput{
        flex: 1;
        color:#343434;
        padding: 1.125rem 1.125rem;
        border:none
    }
    input::input-placeholder{
        color:#DEDEDE;
    }
    .searchBtn{
        cursor: pointer;
        padding: 1.125rem 3.81rem;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        background: #0089D2;
        color: #fff;
    }
    .mobile-join-header {
        width: 100%;
        margin: 0;
        padding: 0 2rem 1rem 2rem;
    }
    .screen {
        width: 3rem;
        height: 3rem;
    }
    .screenInptBody {
        width: calc(100% - 10rem);
        height: 4rem;
        border-radius: .6rem;
        padding: 1rem;
        background-color: #fff;
    }
    .screenSear {
        width: 1.6rem;
        height: 1.6rem;
    }
    .screenInpt {
        border: none;
        width: calc(100% - 2rem);
        padding-left: 1rem;
    }
    .screenSearch {
        font-size: 1.56rem;
        color: #008AD4;
    }
    .monolayer {
        height: 50rem;
    }
    .mobileChoose {
        position: absolute;
        top: 0;
        display: none;
        width: 100%;
        background-color: #fff;
        padding: 2rem;
        border-radius: 0 0 1rem 1rem;
        box-shadow: 0 0 10px 5px #ddd;
    }
    .choose-title {
        font-size: 1rem;
        font-weight: 600;
        margin: 1.5rem 0;
    }
    .noChoosebtn {
        background-color: #F7F7F7;
        color: #ADADAD;
        margin: 0 1rem 1rem 0;
        width: calc(25% - 1rem);
    }
    .choosebtn {
        background-color: #008AD4;
        color: #fff!important;
        margin: 0 1rem 1rem 0;
        width: calc(25% - 1rem);
    }
    .jobs {
        padding: 2rem;
    }
    .jobs-list {
        background-color: #fff;
        margin-bottom: 1.6rem;
        padding: 1.5rem;
        border-radius: .2rem;
    }
    .jobs-title {
        font-size: 1.8rem;
        color: #4D4948;
    }
    .contentAdd {
        color: #ADADAD;
        margin: 1rem 0;
    }
    .jobs-empty {
        background-color: #fff;
        height: 10rem;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
</style>