<template>
  <div style="margin-top: 5rem;">
    <img :src="bannerImg" alt="" srcset="" class="sensorBanner">
    <div class="divView" :style="{ background: '#F5F5F5' }">
      <div class="childerNavBox">
        <ChilderNav :obj="childerNav" backColor="#fff" :navIndex="childeIndex" @parentFun="childerNavFun" />
      </div>
      <div class="sensorProduct" v-for="(item, index) of productLi" :key="index" :id="'list' + index">
        <div class="productType">
          <img src="../../assets/image/logoIcon.jpg" alt="" srcset="" class="typeIcon">
          <h1 class="typeTitle">{{ item.type_name }}</h1>
        </div>
        <ul class="productUl">
          <li v-for="(items, indexs) of item.child_data" :key="indexs"
            :class="['liBox', indexs % 2 == 0 ? 'listRight' : 'listLeft']"
            @click="this.$router.push({ name: 'ProductDetails', query: { sensor_name: items.sensor_name } });">
            <div class="productLi">
              <div class="productImg productTop">
                <img :src="imgUrl + items.cover" alt="" srcset="" class="productImgUrl" />
              </div>
              <div class="productContent">
                <div>
                  <p class="productTitle">{{ items.sensor_name }}</p>
                  <p class="productDateils size16" v-html="items.description"></p>
                </div>
                <div class="productMore">
                  <span class="moreTxt size16">{{ $t("main.learnMore") }}</span>
                  <img src="../../assets/image/right.jpg" alt="" srcset="" class="moreIcon" />
                </div>
              </div>
              <div class="productImg productRight">
                <img :src="imgUrl + items.cover" alt="" srcset="" class="productImgUrl" />
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>
<script>
import { POST, ldImg } from '../../http/api'
import ChilderNav from '../module/childerNav.vue'
import { useRoute } from "vue-router";
import { onMounted, watch, reactive, toRefs } from "vue"
import editorCss from '@/utils/editorCss'
export default {
  components: {
    ChilderNav,
  },
  props: ['navData'],
  setup(props, context) {
    const $route = useRoute();
    const state = reactive({
      productLi: [],
      childeIndex: null,
      childerNav: [],
      bannerImg: null,
      imgUrl: ldImg(),
    })
    onMounted(() => {
      chiderFun(props.navData ? props.navData.menu_data : null)
      if($route.query.threeIndex) {
          setTimeout(() => {
            const s = `#list${$route.query.threeIndex}`
            const anchor = document.querySelector(s)
            document.body.scrollTop = anchor?.offsetTop - 50
            document.documentElement.scrollTop = anchor?.offsetTop - 50
          }, 100)
      } else {
        $('body,html').animate({
          scrollTop: 0
        })
      }
     
    })

    watch(() => $route.query, async (n, oldProps) => {
      if (n.twoIndex) {
        state.childeIndex = n.twoIndex
        await navListData()
        if(n.type == 'foot') {
          $('body,html').animate({
            scrollTop: 0
          })
        } else {
          setTimeout(() => {
            const s = `#list${n.threeIndex}`
            const anchor = document.querySelector(s)
            document.body.scrollTop = anchor.offsetTop - 50
            document.documentElement.scrollTop = anchor.offsetTop - 50
          }, 100)
        }
      }
    })

    watch(() => props.navData, (n, oldProps) => {
      chiderFun(n.menu_data)
    })

    function chiderFun(data) {
      // const num = window.sessionStorage.getItem('topNavIndex') == 0 ? 0 : window.sessionStorage.getItem('topNavIndex') - 1 ?? 0
      const childerIndex = window.sessionStorage.getItem('childerNavIndex') || 0
      if (data && data[0]) {
        state.childerNav = data[0].module_data
        state.childeIndex = childerIndex
        navListData()
      }
    }
    function childerNavFun(index) {
      state.childeIndex = index
      navListData();
    }
    function navListData() {
      const languageType = window.sessionStorage.getItem('language') || 'zh'
      POST({
        "language": languageType == 'zh' ? 0 : 1,
        "sensor_series_id": state.childerNav[state.childeIndex].id
      }, 'get_sensor').then((msg) => {
        if (msg.code == 20000) {
          const data = msg.data.series_data[0]
          if (data) {
            data.child_data = data.child_data.filter(item => {
              return item['child_data'].length > 0
            })
            const m = data.child_data.map(item => {
              item['child_data'] = item['child_data'].map(t => {
                t['content'] = editorCss.format(t['content'])
                return t
              })
              return item
            })
            state.productLi = m
            state.bannerImg = state.imgUrl + data.cover
          }
        } else {
          alert(msg.code)
        }
      })
    }
    const refData = toRefs(state)
    return {
      chiderFun, childerNavFun,
      ...refData,
    }
  }
}
</script>
<style scoped>
.productTop {
  display: none;
}

.sensorBanner {
  width: 100%;
  display: block;
}

.sensorProduct {
  padding-top: 3.75rem;
}

.productType {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.typeIcon {
  width: 1.12rem;
  height: 1.12rem;
}

.typeTitle {
  font-family: 'OPPOSans-M';
  color: #4C4948;
  font-size: 1.25rem;
  margin-left: .2rem;
}

.productUl {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.liBox {
  width: 50%;
  margin-top: 2.43rem;
  cursor: pointer;
}

.productLi {
  background: #fff;
  display: flex;
  flex-direction: row;
  border-radius: .93rem;
  height: 100%;
  padding: 3rem 2rem 3rem 3rem;
}

.productContent {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  min-height: 12rem;
  max-height: 15rem;
}

.listLeft {
  padding-left: 1.31rem;
}

.listRight {
  padding-right: 1.31rem;
}

.productTitle {
  color: #4C4948;
  font-size: 1.87rem;
  font-family: 'OPPOSans-B';
}

.productDateils {
  color: #585858;
  margin-top: .5rem;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.productMore {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.moreTxt {
  color: #4C4948;
}

.moreTxt:hover {
  color: #0089D2;
}

.moreIcon {
  width: .575rem;
  height: .8625rem;
  margin-left: .5rem;
}

.productImgUrl {
  width: 9.37rem;
  transition: .5s;
  margin: auto;
}

.productLi:hover .productImgUrl {
  width: 11rem;
}

.productLi:hover .productTitle {
  color: #0089D2;
}

.productImg {
  text-align: center;
  height: 12rem;
}

.productRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>