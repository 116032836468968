import 'babel-polyfill'
import "core-js/stable"
import "regenerator-runtime/runtime"
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router';
import vueI18n from '@/assets/lang/index'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@/assets/font/font.css';
import '@/assets/style/global.css';
import '@/assets/style/base.css';
import '@/assets/style/mediaReset.css'

router.beforeEach((to, from, next) => {
    let n = 0
    if(to.name == 'Ldindex') n = 0
    else if(to.name == 'SensorIndex' || to.name == 'ProductDetails') n = 1
    else if(to.name == 'SolutionIndex') n = 2
    else if(to.name == 'ApplyIndex') n = 3
    else if(to.name == 'ServiceIndex' || to.name == 'AfterSales') n = 4
    else if(to.name == 'AboutIndex' || to.name == 'JoinUsIndex' || to.name == 'LdInformation' || to.name == 'NewDetails')  n = 5
    window.sessionStorage.setItem("topNavIndex", n)
    next()
})

createApp(App).use(vueI18n).use(router).use($).mount('#app')
