<template>
    <div class="titleBox">
        <p class="titleTxt">{{titleTips}}</p>
        <span class="titleBottomBorder"></span>
    </div>
</template>
<script>
/* eslint-disable */
// eslint-disable-next-line vue/no-setup-props-destructure
import { computed } from "vue"
export default {
  data() {
    return {

    }
  },
  props:['titleTxt'],
  setup(props) {
    const title_Txt = props.titleTxt
    const titleTips = computed(() => {
        return props.titleTxt
    })
    return {
        title_Txt,
        titleTips
    }
  },
  methods:{

  },
}
</script>
<style scoped>
    .titleBox{
        text-align:center;
    }
    .titleBottomBorder{
        display: block;
        margin: auto;
        margin-top: 1.25rem;
        width: 2.81rem;
        height: 0.25rem;
        background: #0089D2;
    }
    .titleTxt{
        color:#4C4948;
        font-size: 1.87rem;
        font-family: 'OPPOSans-B';
        letter-spacing:.075rem;
    }
</style>
