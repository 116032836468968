import axios from "axios";
const service = axios.create({
    headers:{ //请求头
        'Content-Type': 'application/json' ,
    },
    settimeout:50000,//超时时间
})
service.interceptors.response.use(response => {
    return Promise.resolve(response.data)
},error => {
    return Promise.reject(error);
})
export default service;