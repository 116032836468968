<template>
    <div class="divView newDetails-container">
        <div class="topTitle border-bottom">
            <h1 class="titleTxt">{{ newsTitle }}</h1>
            <p class="titleTime">{{ time(newsTime) }}</p>
        </div>
        <div class="detailsContent border-bottom" v-html="newsContent"></div>
        <div class="relatedNews">
            <TitleModel :titleTxt="`${$t('main.relatedNews')}`" />
            <div class="relatedUl">
                <div v-for="(item, index) of relatedList" :key="index"
                    :class="['relatedLiist', { hideMargin: index == relatedList.length - 1 }]"
                    @click="this.$router.push({ name: 'NewDetails', query: { id: item.id } })">
                    <img :src="imgUrl + item.cover" alt="" srcset="" class="relatedimg" />
                    <div class="listContent">
                        <h1 class="listTitle size16">{{ item.title }}</h1>
                        <div class="content-foot">
                            <p class="listTime">{{ time(item.update_time) }}</p>
                            <div class="list-r">
                                <span class="btnTxt">{{$t('main.detailsTxt')}}</span>
                                <img src="../../assets/image/right.jpg" alt="" srcset="" class="rightIcon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="moreNews">
                <Botton :titleTxt="`${$t('main.moreNews')}`" @more="moreFun" />
            </div>
        </div>
    </div>
</template>
<script>

import TitleModel from '../module/titleModel.vue'
import { POST, ldImg, changeDateFormat } from '../../http/api'
import { useRoute } from "vue-router"
import { onMounted, watch, reactive, toRefs } from "vue"
import editorCss from '@/utils/editorCss'
import Botton from '../module/botton.vue'
import router from '@/router/index.js'

export default {
    components: {
        TitleModel, Botton
    },
    setup(props, context) {
        const $route = useRoute();
        const state = reactive({
            relatedList: [{}, {}, {}],
            newsTitle: null,
            newsTime: null,
            newsContent: null,
            newsId: $route.query.id || 0,
            imgUrl: ldImg()
        })
        onMounted(() => {
            load()
            $('body,html').animate({
                scrollTop: 0
            })
        })
        watch(() => $route.query.id, (n, o) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
            state.newsId = n
            load()
        })
        function moreFun() {
            context.emit('viewParent', { index: 4 })
            router.push('/LdInformation')
        }
        function load() {
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            POST({
                "language": languageType == 'zh' ? 0 : 1,
                "news_id": state.newsId
            }, 'get_newsInfo').then((msg) => {
                if (msg.code == 20000) {
                    const { news_info, important_news_list } = msg.data
                    const { title, update_time, content } = news_info
                    state.newsTitle = title;
                    state.newsTime = update_time;
                    state.newsContent = editorCss.format(content)
                    state.relatedList = important_news_list
                } else {
                    alert(msg.code)
                }
            })
        }
        function time(time) {
            return changeDateFormat(time)
        }
        const refData = toRefs(state)
        return {
            time, load, moreFun,
            ...refData,
        }
    }
}
</script>
<style scoped>
.newDetails-container {
    width: 80%;
    margin: 5rem auto 0 auto;
}
.topTitle {
    padding: 1.875rem 0;
    border-color: #E3E3E3;
    text-align: center;
}

.relatedLiist {
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    margin-right: 1.875rem;
}

.hideMargin {
    margin-right: 0;
}

.listTime {
    color: #999999;
    font-size: .875rem;
}

.listTitle {
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.listContent {
    background: #F7F7F7;
    height: 7rem;
    border-radius: 0 0 3px 3px;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.relatedimg {
    width: 100%;
    border-radius: 3px 3px 0 0;
    min-height: 12rem;
    max-height: 15rem;
    display: block;
}

.relatedNews {
    padding-top: 5.25rem;
}

.relatedUl {
    margin-top: 5.25rem;
    display: flex;
    flex-direction: row;
}

.titleTxt {
    color: #4C4948;
    font-size: 2.25rem;
    margin-top: 2.9375rem;
}

.titleTime {
    color: #A6A6A6;
    font-size: .875rem;
    margin-top: 1.5rem;
}

.detailsContent {
    padding: 2.5rem 0;
}

.detailsContent>>>p {
    line-height: 2.1875rem;
    font-size: .875rem;
}

.rightIcon {
    width: .4375rem;
    height: .8125rem;
    margin-left: .5625rem;
}
.content-foot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.moreNews {
  display: flex;
  justify-content: center;
  margin: 1.93rem 0;
}
</style>