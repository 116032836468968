<template>
    <div class="footer">
        <ul class="footerUl">
            <li class="footerAdd">
                <img :src="ldLogo" alt="" sizes="" srcset="" class="logoImg">
                <div class="footerCall">
                    <p class="callTxt size16">{{ $t("main.hotline") }}：{{ldTel}}</p>
                    <p class="callTxt size16">{{ $t("main.address") }}：{{ldAddress}}</p>
                </div>
            </li>
            <li class="footerNavBox">
                <div v-for="(item,index) of footerNavList" :key="index" class="footerNavLi">
                    <h1 class="footerNavTitle" @click.stop="openWinFn(index)">{{item.menu_name}}</h1>
                    <p 
                        v-for="(items,indexs) of item.module_data" 
                        :key="indexs" 
                        :class="[
                            'childerLi',
                            {'qrPosition':indexs==item.module_data.length-1&&index==footerNavList.length-1}
                        ]"
                        @click="openWin(index, indexs)"
                    >{{items.first_name}}</p>
                </div>
            </li>
            <li class="qrBox">
                <div class="qrList">
                    <img :src="ldWechat" alt="" sizes="" srcset="" class="qrImg">
                    <p class="qrTxt">{{ $t("main.public") }}</p>
                </div>
            </li>
        </ul>
        <div class="borderFooter"></div>
        <p class="footerRootName">@{{ $t("main.ldTxt") }} ALL RIGHTS RESERVED&nbsp;&nbsp;&nbsp;&nbsp; 备案号：<a class="footerRootName" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备18042865号</a></p>
        <img src="../../assets/image/footerBag.jpg" alt="" sizes="" srcset="" class="footerBag">
    </div>
    <div class="mobileFootre">
        <div class="goTop" @click="clickBackTop">
            <h1 class="goTitle">返回顶部</h1>
            <img src="../../assets/image/icon_arrow_up.png" alt="" sizes="" srcset="" class="goArrow" />
        </div>
        <ul>
            <li v-for="(item,index) of footerNavList" :key="index" class="mobileFooterNavList border-top">
                <div class="mobileFooterLiBox"
                    @click.stop="mobileNav(index)"
                >
                    <span class="listName">{{item.menu_name}}</span>
                    <img src="../../assets/image/icon_arrow_right.png" alt="" sizes="" srcset="" class="listIcon" />
                </div>
                <div class="mobileChilderNav">
                    <div
                        v-for="(items,indexs) of item.module_data" 
                        :key="indexs"
                        class="childerNavName"
                        @click.stop="openWin(index,indexs)"
                    >{{items.first_name}}</div>
                </div>
            </li>
        </ul>
        <div class="mobileCompanyAddress border-top border-bottom">
            <p class="addressDetails">
                {{ $t("main.hotline") }}：{{ldTel}} <br>
                {{ $t("main.address") }}：{{ldAddress}}
            </p>
            <div class="vx" @click.stop="onOpenWeChatFn">
                <img src="../../assets/image/pic_wechat.png" alt="" sizes="" srcset="" class="mobileWechat" />
                <span>乐动机器人官网微信</span>
            </div>
        </div>
        <p class="mobilRecord">@{{ $t("main.ldTxt") }}  <br> ALL RIGHTS RESERVED&nbsp;&nbsp;&nbsp;&nbsp; 备案号：<a class="footerRootName" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备18042865号</a></p>
        <wx-temp v-if="wxFlag" @hideWx="onOpenWeChatFn"></wx-temp>
    </div>
</template>
<script>
  import {onMounted,reactive,toRefs,watch  } from 'vue';
  import {ldImg} from '../../http/api'
  import router from '@/router/index.js'
  import wxTemp from "./maskModule/wx.vue"
  import { useI18n } from 'vue-i18n'
  import logo from "@/assets/image/logo.png"
  import qrcode from "@/assets/image/Qrcode.jpg"

  export default {
    name: 'LdFooter',
    emits:['footerNavFun'],
    props:['footerData'],
    components: {
        wxTemp,
    },
    setup(props, context){
        const { t } = useI18n()
        const state = reactive({
            footerNavList:[],
            ldTel:null,
            ldAddress:null,
            ldLogo: logo,
            ldWechat: qrcode,
            language: window.sessionStorage.getItem('language') ?? 'zh',
            wxFlag: !1,
        });
        onMounted(()=>{
            load()
        })
        watch(props, (n, o) => {
            if(n.footerData){
                const msgData = n.footerData
                state.footerNavList = _.cloneDeep(msgData.menu_data)
                state.footerNavList.unshift({ menu_name: t('main.indexTxt'), module_data: [] })
                state.footerNavList[4].module_data.splice(0, 1)
                state.ldTel = msgData.company_info[0].telephone
                state.ldAddress = msgData.company_info[0].address
                // state.ldLogo = ldImg()+msgData.logo
                // state.ldWechat = ldImg()+msgData.wechat
            }
        })
        function load(){
            setTimeout(()=>{
                const qrHeight = $('.qrList').height()
                const lastHeight = $('.qrPosition').height()+Number($('.childerLi').css('marginTop')?.replace('px','')) ?? 32.4
                const qrTop=$(".qrPosition").position()?.top
                $('.qrList').css({paddingTop:qrTop-(qrHeight-lastHeight)+'px'})
            }, 10)
        }
        function mobileNav(index){
            if(index == 0) router.push('/')
            else {
                const demo = $('.mobileFooterLiBox').eq(index)
                const demoIcon = demo.find('.listIcon')
                if(demoIcon.hasClass('iconTranDeg')) demoIcon.removeClass('iconTranDeg')
                else demoIcon.addClass('iconTranDeg')
                demo.siblings('.mobileChilderNav').slideToggle()
                demo.parent('.mobileFooterNavList').siblings().find('.mobileChilderNav').slideUp()
                demo.parent('.mobileFooterNavList').siblings().find('.listIcon').removeClass('iconTranDeg')   
            }
        }
        // 点击跳转
        const openWinFn = e => {
            context.emit('footerNavFun',{index: e})
            switch(e) {
                case 0:
                    router.push('/')
                    break;
                case 1:
                    router.push('/SensorIndex')
                    break;
                case 2:
                    router.push('/SolutionIndex')
                    break;
                case 3:
                    router.push('/ApplyIndex')
                    break;
                case 4:
                    router.push('/AfterSales')
                    break;
                case 5:
                    router.push('/AboutIndex')
                    break;
            }
        }
        function openWin(index, twoIndex) {
            context.emit('footerNavFun',{index})
            switch (index - 1) {
                case 0:
                    router.push({name:'SensorIndex',query:{twoIndex:twoIndex, type: 'foot'}})
                    break;
                case 1:
                    router.push({name:'SolutionIndex',query:{twoIndex:twoIndex, type: 'foot'}})
                    break;
                case 2:
                    router.push({name:'ApplyIndex',query:{twoIndex:twoIndex, type: 'foot'}})
                    break;
                case 3:
                    switch (twoIndex) {
                        case 0:
                            router.push({name:'AfterSales', query: { salesId: 1 }})
                            break;
                    
                        case 1:
                            router.push({name:'AfterSales', query: { salesId: 2 }})
                            break;
                    }
                    break;
                case 4:
                    switch (twoIndex) {
                        case 0:
                            router.push({name:'AboutIndex'})
                            break;
                        case 1:
                            router.push({name:'JoinUsIndex'})
                            break;
                        case 2:
                            router.push({name:'LdInformation'})
                            break;
                        case 3:
                            router.push({name:'CooperationLd'})
                            break;
                    }
                    break;
            }
            $('.mobileChilderNav').slideUp()
            $('.listIcon').removeClass('iconTranDeg')
        }
        function clickBackTop(){
            document.body.scrollTop = document.documentElement.scrollTop = 0
        }
        function onOpenHomePage() {
            router.push('/')
        }

        // 打开微信
        const onOpenWeChatFn = () => {
            state.wxFlag = !state.wxFlag
        }
        
        const refData = toRefs(state)
        return {
            openWin,clickBackTop,mobileNav,onOpenHomePage,openWinFn, onOpenWeChatFn,
            ...refData,
        }
    },
  }
</script>
<style scoped>
    .mobileCompanyAddress{
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 4rem 0;
        border-color: rgba(255,255,255,.2) !important;
    }
    .vx {
        display: flex;
        flex-direction: row;
        color: #fff;
        margin: 3rem auto 0 auto;
        align-items: center;
    }
    .vx span {
        font-size: 14px;
        color: rgba(255,255,255,.5);
    }
    .mobileChilderNav{
        display: none;
    }
    .childerNavName{
        font-size: 1.5rem;
        color: rgba(255,255,255,.5);
        margin-top: 2.5rem;
    }
    .mobileWechat{
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 1rem;
    }
    .addressDetails{
        flex: 1;
        color: rgba(255,255,255,.5);
        font-size: 1.5rem;
        line-height: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .mobilRecord{
        text-align: center;
        color: rgba(255,255,255,.5);
        font-size: 1.25rem;
        padding-bottom: 2.75rem;
        padding-top: 2rem;
    }
    .mobileFooterNavList{
        padding: 2.5rem 0;
        border-color: rgba(255,255,255,.2) !important;
    }
    .mobileFooterLiBox{
        display: flex;
        flex-direction: row;
    }
    .iconTranDeg{
        transform:rotate(90deg)
    }
    .listName{
        flex: 1;
        color:#fff;
        font-size: 1.75rem;
    }
    .listIcon{
        width: 1.5rem;
        height: 1.5rem;
        transition: .2s;
    }
    .mobileFootre{
        display: none;
        padding: 0 1rem;
        background: #333333;
    }
    .goTop{
        height: 5.4375rem;
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content:center;
    }
    .goTitle{
        color: #fff;
        font-size: 1.5rem;
    }
    .goArrow{
        width: 1.5rem;
        height: 1.5rem;
        margin-left: .75rem;
    }
    .footer{
        padding: 4.8rem 15rem 5.8rem 15rem;
        background: #333333;
        position: relative;
    }
    .footerBag{
        position: absolute;
        width: 39.12rem;
        height:30rem ;
        bottom: 0;
        right: 0;
    }
    .footerRootName{
        margin-top: 1rem;
        color:#ADADAD;
        font-size: .75rem;
    }
    .borderFooter{
        height: 1px;
        background: #959595;
        margin-top: 4.87rem;
    }
    .qrTxt{
        color:#ADADAD;
        font-size: .87rem;
    }
    .qrList{
        text-align: center;
    }
    .qrImg{
        height: 6.18rem;
        width: 6.18rem;
    }
    .footerNavBox{
        flex: 1;
        display: flex;
        flex-direction: row;
    }
    .footerNavTitle{
        color:#E3E3E3;
        padding-bottom: 1.1rem;
        font-size: 1.12rem;
        cursor: pointer;
    }
    .childerLi{
        color:#ADADAD;
        margin-top: .9rem;
        font-size: .87rem;
        cursor: pointer;
    }
    .childerLi:hover{
        color:#0089D2;
    }
    .footerNavLi{
        flex: 1;
        margin-right: .5rem;
        position: relative;
    }
    .footerUl{
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
    }
    .footerAdd{
        width: 35rem;
        padding-right: 4.8rem;
    }
    .logoImg{
        width: 15.6rem;
        height: 3.8rem;
    }
    .footerCall{
        margin-top: 3.5rem;
    }
    .callTxt{
        color:#ADADAD;
        margin-top: 1.1rem;
    }
</style>
