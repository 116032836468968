
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Ldindex from '../components/Ldindex.vue'
import SensorIndex from '../components/sensor/sensorIndex.vue'
import ProductDetails from '../components/sensor/productDetails.vue'
import SolutionIndex from '../components/solution/solutionIndex.vue'
import ApplyIndex from '../components/apply/applyIndex.vue'
import ServiceIndex from '../components/service/serviceIndex.vue'
import AfterSales from '../components/service/afterSales.vue'
import JoinUsIndex from '../components/aboutLd/joinUsIndex.vue'
import AboutIndex from '../components/aboutLd/aboutIndex.vue'
import LdInformation from '../components/aboutLd/ldInformation.vue'
import CooperationLd from '../components/aboutLd/cooperationLd.vue'
import NewDetails from '../components/details/newDetails.vue'

const routes = [
    // {
    //     path:"/", // 路由地址
    //     redirect:"/Ldindex" // 重定向
    // },
    {
        path: '/',
        name: 'Ldindex',
        component: Ldindex//首页
    },
    {
        path: '/SensorIndex',
        name: 'SensorIndex',
        component: SensorIndex,//传感器
    },
    {
        path: '/ProductDetails',
        name: 'ProductDetails',
        component: ProductDetails,//产品详情
    },
    {
        path: '/SolutionIndex',
        name: 'SolutionIndex',
        component: SolutionIndex//解决方案
    },
    {
        path: '/ApplyIndex',
        name: 'ApplyIndex',
        component: ApplyIndex//应用领域
    },
    {
        path: '/ServiceIndex',
        name: 'ServiceIndex',
        component: ServiceIndex//下载中心
    },
    {
        path: '/AfterSales',
        name: 'AfterSales',
        component: AfterSales//售前售后
    },
    {
        path: '/AboutIndex',
        name: 'AboutIndex',
        component: AboutIndex//关于我们
    },
    {
        path: '/JoinUsIndex',
        name: 'JoinUsIndex',
        component: JoinUsIndex//加入我们
    },
    {
        path: '/LdInformation',
        name: 'LdInformation',
        component: LdInformation//公司资讯
    },
    {
        path: '/CooperationLd',
        name: 'CooperationLd',
        component: CooperationLd//合作伙伴
    },
    {
        path: '/NewDetails',
        name: 'NewDetails',
        component: NewDetails//新闻详情
    },
    
]
//3.创建路由的实例对象
const router = createRouter({
    // history: createWebHashHistory(),//哈希模式
    history: createWebHistory(),//h5 History 模式   这种方式上线需要服务器配置伪静态
    routes
})
export default router