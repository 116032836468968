<template>
    <div :class="['childerBox', { 'border-bottom': isMobile }]">
        <ul class="childerNavUl" :style="{ background: backColor }" @mouseleave="moveOutFun">
            <li v-for="(item, index) of objArea" :key="index" :class="[
                'childerNavLi',
                {
                    navLiActive: navLiIndex == index,
                    'border-right': index < objArea.length - 1 && !isMobile
                }
            ]" @click="clickNavFun(index)" @mouseenter="navAnimate(index)">
                <span class="liTitle">{{ item.first_name }}</span>
            </li>
            <i class="navAnimate"></i>
            <i class="mobileAnimate"></i>
        </ul>
    </div>
</template>
<script>
/* eslint-disable */
// eslint-disable-next-line vue/no-setup-props-destructure
import { watch, reactive, toRefs, onMounted } from 'vue';

export default {
    components: {},
    props: ['obj', 'backColor', 'navIndex', 'navData'],
    setup(props, context) {
        const state = reactive({
            objArea: props.obj,
            backColor: props.backColor,
            navLiIndex: props.navIndex,
            isMobile: false,
        })
        onMounted(() => {
            load()
        })
        watch(() => props.navIndex, (newProps, oldProps) => {
            state.navLiIndex = newProps
            setTimeout(() => {
                mobileSelNavAnimate()
            }, 500)
        })
        watch(() => props.obj, (newProps, oldProps) => {
            state.objArea = newProps
            winResize()
        })
        function load() {
            const childerNavIndex = window.sessionStorage.getItem('childerNavIndex') || 0
            state.navLiIndex = childerNavIndex;
            $(window).resize(function () {
                winResize()
            })
            winResize()
        }
        function winResize() {
            const isTrue = $(window).width() >= 870 ? false : true
            state.isMobile = isTrue
            if (isTrue) {
                setTimeout(() => {
                    const liLength = $('.childerNavLi').length
                    const boxWidth = $('.childerNavUl').innerWidth()
                    let ulWidth = 0
                    for (let index = 0; index < liLength; index++) {
                        const element = $('.childerNavLi').eq(index).innerWidth()
                        ulWidth += element
                    }
                    if (ulWidth == boxWidth) return
                    $('.childerNavUl').css({ width: ulWidth + 5 })
                }, 500)
            } else {
                $('.childerNavUl').css({ width: 'auto' })
            }
        }
        function navAnimate(index) {
            const animateWidth = $('.childerBox').width() / state.objArea.length
            const animateHight = $('.childerBox').height()
            $('.navAnimate').css({ width: animateWidth + 'px', height: animateHight + 'px', left: animateWidth * index + 'px' })
        }
        function mobileSelNavAnimate() {
            const childerNavIndex = Number(state.navLiIndex)
            const navLength = childerNavIndex + 1
            const i_Width = $('.mobileAnimate').innerWidth() / 2
            let animateWidth = 0;
            let selNavWidth = 0;
            for (let i = 0; i < navLength; i++) {
                const element = $('.childerNavLi').eq(i).innerWidth()
                if (childerNavIndex == i) {
                    selNavWidth = element
                }
                animateWidth += element
            }
            $('.mobileAnimate').css({ left: animateWidth - i_Width - selNavWidth / 2 + 'px' })
        }
        function moveOutFun() {
            $('.navAnimate').css({ left: 0, width: 0 })
        }
        function clickNavFun(index) {
            const ulHeight = $(".childerBox").height() + 20
            state.navLiIndex = index
            window.sessionStorage.setItem("childerNavIndex", index)
            context.emit('parentFun', index, ulHeight)
            mobileSelNavAnimate()
        }
        const refData = toRefs(state)
        return {
            clickNavFun, navAnimate, moveOutFun,
            ...refData,
        }
    },
}
</script>
<style scoped>
.childerNavUl {
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: .93rem;
    border-bottom-right-radius: .93rem;
    position: relative;
}

.navAnimate {
    position: absolute;
    left: 0;
    top: 0;
    transition: .5s;
    background: #EEEEEE;
    border-bottom-left-radius: 0.93rem;
    border-bottom-right-radius: 0.93rem;
}

.mobileAnimate {
    position: absolute;
    height: .25rem;
    width: 1.8125rem;
    background: #008AD4;
    left: 0;
    bottom: 0;
    transition: .5s;
    display: none;
}

.childerNavLi {
    flex: 1;
    padding: 1.06rem 0;
    text-align: center;
    cursor: pointer;
    z-index: 1;
}

.navLiActive {
    background: #0089D2;
    border-bottom-left-radius: .93rem;
    border-bottom-right-radius: .93rem;
}

.delBorder {
    border: none;
}

.liTitle {
    color: #ADADAD;
    font-size: 1.25rem;
}

.navLiActive span {
    color: #fff;
}
</style>