<template>
  <div :class="['fixedClass']" @mouseleave="navHideFun()">
    <div class="rowUl">
      <img :src="logoImg" alt="" sizes="" srcset="" class="logoImg" @click="openWin(0, 'default')">
      <ul class="listUl">
        <li v-for="(item, index) of nvaList" :key="index" :class="[
          'list', 'size16', 'nvaSizeColor', 'navLi',
          {
            active: navIndex == index || clickIndex == index,
          }
        ]" @mouseenter="navShowFun(index)" @click="openWin(index, 'default')">
          {{ item.menu_name }}
          <i :class="['active_i', { selsetActive: clickIndex == index }]"></i>
        </li>
        <li :class="[
          'list', 'size16', 'nvaSizeColor', 'navLi',
        ]" @click="openWin(6, 'default')"> <span :class="[{ lagActive: langType == 'zh' }]">CN</span> / <span
            :class="[{ lagActive: langType == 'en' }]">EN</span></li>
      </ul>
    </div>
    <div class="collnavContent" @click.stop="navHideFun()">
      <ul class="navContent">
        <li v-for="(item, index) of childerList" :key="index"
          :class="['contentLi', { borderRight: index != childerList.length - 1, overScroll: item.isScroll }]">
          <h1 class="contentTitle" @click="openTwoWeb(index)">{{ item.first_name }}</h1>
          <div class="childerLi" v-for="(items, indexs) of item.child_data" :key="indexs">
            <h2 :class="['chiderTitle', 'size16', { titltTop: indexs == 0 }]" style="font-weight: bold"
              @click="onOpenThreeWebFn(index, indexs)">{{
                items.second_name }}
            </h2>
            <span v-for="(item_s, index_s) of items.child_data" :key="index_s" class="chiderTitle size16 chiderSpan"
              style="font-weight: 500;color: #222629; font-size: 14px;" @click="detailsWep(item_s.sensor_name)">{{
                item_s.sensor_name }}</span>
          </div>
        </li>
      </ul>
      <div class="moreBox" v-show="showMoreBtn">
        <Botton :titleTxt="`${$t('main.learnMore')}`" @more="moreFun" />
      </div>
    </div>
    <!-- 移动端显示 -->
    <div :class="['fixedClass', { fixeRadio: showMobileNav }]" style="display: none">
      <div class="rowMobile">
        <img
          :src="showMobileNav ? require('../../assets/image/icon_nav_close.png') : require('../../assets/image/icon_nav_list.png')"
          alt="" sizes="" srcset="" class="iconNav" @click="openMobileNav">
        <div class="mobileLogo">
          <img :src="logoImg" alt="" sizes="" srcset="" class="logoImg">
        </div>
        <span class="mobileLangType" @click="openWin(6, 'default')">{{ langType == 'zh' ? 'EN' : 'CN' }}</span>
      </div>
      <ul class="mobileCollnav">
        <li v-for="(item, index) of nvaList" :key="index" class="mobileCollnavList" @click="openWin(index, 'mobile')">
          {{ item.menu_name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Botton from './botton.vue'
import { POST, ldImg } from '../../http/api'
import { onMounted, reactive, toRefs, inject, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router/index.js'
import logo from "@/assets/image/logo.png"
import _ from "lodash"
export default {
  name: 'LdHeader',
  components: {
    Botton
  },
  props: ['addHeadedr', 'headerNavIndex'],
  setup(props, context) {
    const { t, locale } = useI18n()
    const reload = inject('reload')
    const state = reactive({
      navIndex: 0,
      childerList: [{}, {}, {},],
      clickIndex: 0,
      langType: window.sessionStorage.getItem('language') ?? 'zh',
      nvaList: [],
      logoImg: logo,
      showMoreBtn: false,
      showMobileNav: false,
      // sTFlag: !1,		// 是否固定顶部
    });
    onMounted(() => {
      load()
    })

    watch(() => props.headerNavIndex, (n, o) => {
        state.clickIndex = n
        navShowFun(state.clickIndex, 1)
    }, { deep: true })

    function load() {
      const languageType = window.sessionStorage.getItem('language') || 'zh'
      POST({ "language": languageType == 'zh' ? 0 : 1 }, 'get_test').then((msg) => {
        const msgData = msg.data
        msgData.menu_data[1].module_data.push({ first_name: t('main.moreCustomized') })
        context.emit('addHeadedr', JSON.parse(JSON.stringify(msgData)))
        state.nvaList = msgData.menu_data
        state.nvaList.unshift({ menu_name: t('main.indexTxt'), module_data: [] })
        // state.logoImg = ldImg() + msgData.logo
        const num = window.sessionStorage?.getItem('topNavIndex') ?? 0
        state.clickIndex = num ? num : 0 ?? 0
        navShowFun(num, 1)
      })
    }
    function navShowFun(index, type) {
      const series_data = state.nvaList[index] ? state.nvaList[index].module_data : []
      state.navIndex = index
      state.showMoreBtn = index == 1 ? true : false
      $('.list').eq(index).find('.active_i').stop().animate({ width: '100%' })
      $('.list').eq(index).siblings().find('.active_i').stop().animate({ width: '0' })
      if (type) return
      if (!series_data.length) {
        $('.collnavContent').css({ maxHeight: '0' })
        return
      }
      for (let i = 0; i < series_data.length; i++) {
        if (!series_data[i].child_data || !series_data[i].child_data.length) break
        let indexNum = 0;
        for (let j = 0; j < series_data[i].child_data.length; j++) {
          indexNum += series_data[i].child_data[j].child_data.length
          if (indexNum > 5) {
            series_data[i].isScroll = true
          }
        }
      }
      state.childerList = series_data ? series_data : state.childerList
      $('.collnavContent').css({ maxHeight: '1000px' })
    }
    function navHideFun() {
      state.navIndex = null
      $('.collnavContent').css({ maxHeight: '0' })
      $('.list').eq(state.clickIndex).siblings().find('.active_i').stop().animate({ width: '0' })
    }
    function openTwoWeb(twoIndex) {
      window.sessionStorage.setItem("topNavIndex", state.navIndex)
      window.sessionStorage.setItem('childerNavIndex', twoIndex)
      state.clickIndex = state.navIndex
      switch (state.navIndex) {
        case 1:
          router.push({ name: 'SensorIndex', query: { twoIndex: twoIndex } })
          break;
        case 2:
          router.push({ name: 'SolutionIndex', query: { twoIndex: twoIndex } })
          break;
        case 3:
          router.push({ name: 'ApplyIndex', query: { twoIndex: twoIndex } })
          break;
        case 4:
          switch (twoIndex) {
            case 0:
              router.push({ name: 'ServiceIndex' })
              break;
            case 1: case 2:
              router.push({ name: 'AfterSales', query: { salesId: twoIndex } })
              break;
          }
          break;
        case 5:
          switch (twoIndex) {
            case 0:
              router.push({ name: 'AboutIndex' })
              break;
            case 1:
              router.push({ name: 'JoinUsIndex' })
              break;
            case 2:
              router.push({ name: 'LdInformation' })
              break;
            case 3:
              router.push({ name: 'CooperationLd' })
              break;
          }
          break;
      }
    }

    // 跳转二级并处理
    const onOpenThreeWebFn = (o, t) => {
      window.sessionStorage.setItem("topNavIndex",1)
      router.push({ name: 'SensorIndex', query: { twoIndex: o, threeIndex: t } })
      
    }

    function moreFun() {
      state.clickIndex = state.navIndex == 3 ? 5 : state.navIndex
      window.sessionStorage.setItem("topNavIndex", state.clickIndex)
      window.sessionStorage.setItem('childerNavIndex', 0)
      switch (state.navIndex) {
        case 1:
          router.push('/SensorIndex');
          break;
        case 3:
          router.push('/CooperationLd')
          break;
      }
    }
    function detailsWep(id) {
      state.clickIndex = state.navIndex
      window.sessionStorage.setItem("topNavIndex", state.clickIndex)
      router.push({ name: 'ProductDetails', query: { sensor_name: id } })
    }
    function openMobileNav() {
      $('.mobileCollnav').slideToggle()
      state.showMobileNav = !state.showMobileNav
    }

    // 点击打开对应的tab
    const openWin = (index, type) => {
      state.clickIndex = index == 6 ? state.clickIndex : index
      window.sessionStorage.setItem("topNavIndex", index == 6 ? state.clickIndex : index)
      window.sessionStorage.setItem('childerNavIndex', 0)
      switch (index) {
        case 0:
          router.push('/');
          break;
        case 1:
          router.push('/SensorIndex');
          break;
        case 2:
          router.push('/SolutionIndex');
          break;
        case 3:
          router.push('/ApplyIndex');
          break;
        case 4:
          router.push('/AfterSales')
          // if(type == 'mobile') router.push('/AfterSales')
          // else router.push('/ServiceIndex')
          break;
        case 5:
          router.push('/AboutIndex');
          break;
        case 6:
          state.langType = state.langType == 'en' ? 'zh' : 'en'
          locale.value = state.langType
          window.sessionStorage.setItem("language", state.langType)
          load()
          reload()
          return true
      }
      $('.mobileCollnav').slideUp()
      state.showMobileNav = !state.showMobileNav
    }

    const refData = toRefs(state)
    return {
      navShowFun, navHideFun, openTwoWeb, moreFun, load, detailsWep, openMobileNav, openWin,onOpenThreeWebFn,
      ...refData
    }
  }
}
</script>
<style scoped>
.mobileCollnav {
  padding: 2.5rem 2rem .6875rem 2rem;
  display: none;
}

.mobileCollnavList {
  color: #4D4948;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2.75rem;
}

.rowMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 5.4375rem;
  padding: 0 2rem;
}

.iconNav {
  width: 3rem;
  height: 3rem;
}

.mobileLogo {
  flex: 1;
  text-align: center;
}

.mobileLangType {
  color: #4d4948;
  font-size: 1.75rem;
}

.hader {
  position: relative;
}

.contentTitle:hover,
.chiderTitle:hover {
  color: #0089D2;
  cursor: pointer;
}

.titltTop {
  margin-top: 1rem;
}

.chiderSpan {
  display: block;
  font-family: 'OPPOSans-L';
}

.moreBox {
  display: flex;
  justify-content: left;
  padding: 1.8rem 0rem 1.8rem 20rem;
}

.chiderTitle {
  color: #585858;
  padding: .9rem 0;
}

.contentLi {
  padding: 0 4.4rem;
}

.borderRight {
  border-right: 1px solid #DEDEDE;
}

.contentTitle {
  color: #4C4948;
  font-size: 1.25rem;
  font-family: 'OPPOSans-M';
  margin-bottom: 1rem;
  font-weight: revert;
}

.lagActive {
  color: #0089D2;
  font-weight: bold;
}

.navContent {
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #DEDEDE;
}

.collnavContent {
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 999;
  /* padding: 0 15rem; */
  max-height: 0;
  transition: all .5s linear;
  overflow: hidden;
}

.overScroll {
  height: 20rem;
  overflow-y: scroll;
}

.overScroll::-webkit-scrollbar {
  width: 2px;
}

.overScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.overScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.rowUl {
  display: flex;
  flex-direction: row;
  padding: 0.9rem 15rem;
  border-bottom: 1px solid #DEDEDE;
}

.logoImg {
  width: 12.25rem;
  height: 3.06rem;
  display: block;
  margin: auto;
}

.listUl {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navLi {
  margin-left: 2.4rem;
}

.list {
  cursor: pointer;
  position: relative;
}

.active_i {
  position: absolute;
  height: 1px;
  background: #0089D2;
  bottom: -1.3rem;
  left: 50%;
  transform: translateX(-50%);
}

.active {
  color: #0089D2
}

.selsetActive {
  width: 100% !important;
}

.fixedClass {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0 0 12px rgba(0, 0, 0, .3);
  background: #fff;
}

.fixeRadio {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>
