<template>
    <div style="margin-top: 5rem;">
        <img :src="serviceBanner" alt="" srcset="" class="aleftbanner">
        <div class="divView afterSalesBox" :style="{ background: '#fff' }">
            <div class="titleBox">
                <p class="titleTxt">{{ suppot.title }}</p>
                <span class="titleBottomBorder"></span>
            </div>
            <p class="topDetails">{{ suppot.description }}</p>
            <div class="detailsIntroduce">
                <div v-html="suppot.content"></div>
                <img :src="suppot.cover" alt="">
            </div>

            <div class="contactBox">
                <div class="titleBox">
                    <p class="titleTxt">{{ datum.contact_title }}</p>
                    <span class="titleBottomBorder"></span>
                </div>
                <div class="contactUl">
                    <div v-for="(item, index) of datum.information_set" :key="index" class="ulList">
                        <h1 class="listName">{{ item.title }}</h1>
                        <div :class="[{ listBox: index == 1 }, 'serverBox']">
                            <div class="listContent border">
                                <img :src="item.icon" alt="" srcset="" class="contactIcon">
                                <p :class="['contactTxt size16', index == 2 ? 'mobileSizes' : 'mobileSize']">{{ item.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inputBox domBox">
                <UserInput />
            </div>

            <div class="qualityBox border-top domBox">
                <div class="titleBox">
                    <p class="titleTxt">{{ datum.quality_system }}</p>
                    <span class="titleBottomBorder"></span>
                </div>
                <p class="topDetails">{{ datum.quality_description }}</p>
                <div class="qualityUl">
                    <div v-for="(item, index) of datum.content_set" :key="index"
                        :class="['qualityLi', { 'border-top': index > 2 }]">
                        <img :src="item.icon" :alt="item.title" srcset=""
                            :class="['qualityIcon', index % 3 == 0 ? 'hideMargin' : null]">
                        <div class="qualityContent">
                            <h1 class="qualityName size16">{{ item.title }}</h1>
                            <p class="qualityTxt">{{ item.content }}</p>
                        </div>
                        <span v-show="item.showBorder"
                            :class="['boderStyle', index > 2 ? 'borderTop' : 'borderBottom', 'border-right']"> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TitleModel from '../module/titleModel.vue'
import UserInput from '../module/userInput.vue'
import { POST, ldImg } from '../../http/api'
import { onMounted, reactive, toRefs, watch } from "vue";
import { useI18n } from 'vue-i18n'
import { useRoute } from "vue-router"
import editorCss from '@/utils/editorCss'
export default {
    components: {
        TitleModel, UserInput
    },
    props: ['navData'],
    setup(props, context) {
        const { t } = useI18n()
        const $route = useRoute();
        const state = reactive({
            datum: new Object(),    // 售前 / 售后
            suppot: new Object(),   // 技术支持
            developersEmail: null,
            serviceBanner: null,
            salesId: $route.query.salesId,
        })
        onMounted(() => {
            onFn()
            bannerImg()
            scrollBody(state.salesId)
        })

        watch(() => $route.query, (nweProps, oldProps) => {
            if (nweProps.salesId) scrollBody(nweProps.salesId)
        })

        watch(() => props.navData, (n, o) => {
            bannerImg()
        }, { deep: true, immediate: true })

        function scrollBody(id) {
            setTimeout(() => {
                const bannerHeight = $('.aleftbanner').height()
                const titleHeight = $('.titleBox').height()
                const detailsTopHeight = $('.detailsIntroduce').height()
                const scrollHeight = bannerHeight + titleHeight + detailsTopHeight + 200
                $('body,html').animate({
                    scrollTop: id == 2 ? scrollHeight : 0
                })
            }, 100)
        }

        // 主体方法
        function onFn() {
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            serviceFn(languageType)
            supportFn(languageType)
        }

        // 查询技术支持
        function supportFn(languageType) {
            POST({ "language": languageType == 'zh' ? 0 : 1 }, 'get_service_support').then(r => {
                r.data.content = editorCss.format(r.data['content'])
                state.suppot = r.data
            }).catch(e => {
                console.log(e)
            })
        }

        // 查询售前 / 售后
        function serviceFn(languageType) {
            POST({ "language": languageType == 'zh' ? 0 : 1 }, 'get_sales_service').then(r => {
                state.datum = r.data
            }).catch(e => {
                console.log(e)
            })
        }

        function bannerImg() {
            if (!props.navData) return
            const { service_banner } = props.navData
            state.serviceBanner = ldImg() + service_banner
        }
        const refData = toRefs(state)
        return {
            ...refData
        }
    }
}
</script>
<style scoped>
.aleftbanner {
    width: 100%;
    display: block;
}

.inputBox {
    margin-top: 3.125rem;
}

.contactBox {
    margin-top: 3.125rem;
}

.listBox {
    padding: 0 2rem;
}

.contactUl {
    display: flex;
    flex-direction: row;
    margin-top: 3.125rem;
}

.listContent {
    width: 100%;
    height: 7rem;
    border-color: #DDDDDD;
    text-align: center;
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.contactIcon {
    max-width: 1.875rem;
    max-height: 1.875rem;
}

.contactTxt {
    color: #585858;
    font-family: 'OPPOSans-M';
}

.ulList {
    flex: 1;
}

.listName {
    color: #585858;
    font-size: .875rem;
    text-align: center;
    margin-bottom: 1.25rem;
}

.qualityUl {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.qualityContent {
    margin-left: 1.875rem;
    padding-right: 5rem;
}

.qualityName {
    color: #585858;
    font-family: 'OPPOSans-M';
}

.qualityTxt {
    color: #585858;
    font-size: .875rem;
    font-family: 'OPPOSans-M';
}

.qualityIcon {
    width: 2.25rem;
    height: 2.25rem;
    margin-left: 5rem;
}

.hideMargin {
    margin-left: 0;
}

.qualityLi {
    width: 33.3333%;
    padding: 3.125rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.qualityLi:nth-child(2), .qualityLi:nth-child(5) {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.borderTop {
    top: 0;
}

.borderBottom {
    bottom: 0;
}

.boderStyle {
    position: absolute;
    border-color: #DEDEDE;
    width: 1px;
    height: 6rem;
    right: 0;
}

.qualityBox {
    padding-top: 3.0625rem;
    margin-top: 2.4375rem;
}

.afterSalesBox {
    margin-top: 5.3125rem;
}

.topDetails {
    color: #585858;
    font-size: 1.25rem;
    text-align: center;
    margin: 2.375rem 0 3rem 0;
}

.detailsIntroduce {
    margin-top: 2.625rem;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}

.detailsIntroduce div {
    width: 55%
}

.detailsIntroduce img {
    width: 45%;
    border-radius: 1%;
}

.IntroduceLeft {
    flex: 1;
}

.IntroduceTitle {
    color: #4C4948;
    font-size: 1.25rem;
    font-family: 'OPPOSans-M';
}

.IntroduceCotent {
    color: #585858;
    font-size: .875rem;
    margin-top: 1.125rem;
    white-space: pre-wrap;
}

.childerTitle {
    color: #4C4948;
    margin-top: 1.75rem;
}

.detailsRightIcon {
    width: 37.625rem;
    display: block;
}

.supportTxt {
    margin-top: 4.8125rem;
}

.titleBox{
        text-align:center;
    }
    .titleBottomBorder{
        display: block;
        margin: auto;
        margin-top: 1.25rem;
        width: 2.81rem;
        height: 0.25rem;
        background: #0089D2;
    }
    .titleTxt{
        color:#4C4948;
        font-size: 1.87rem;
        font-family: 'OPPOSans-B';
        letter-spacing:.075rem;
    }
</style>