<template>
  <div class="my-pagination">
    <a href="javascript:;" :class="{ disabled: currentNum === 1 }" @click="changePage(false)">{{$t('main.previousPage')}}</a>
    <span v-if="currentNum > 3">...</span>
    <a
        href="javascript:;"
        v-for="item in list"
        :key="item"
        :class="{ active: currentNum === item }"
        @click="changePage(item)"
    >{{ item }}</a>
    <span v-if="currentNum < pages - 2">...</span>
    <a href="javascript:;" :class="{ disabled: currentNum === pages }" @click="changePage(true)">{{$t('main.nextPage')}}</a>
  </div>
</template>
<script>
import { onMounted,watch,reactive,toRefs,computed} from "vue";
export default {
  components: {

  },
  props: {
    total: {
        type: Number,
        default: 88
    },
    //页面大小
    pagesize: {
        type: Number,
        default: 0
    },
    //当前显示的页码
    currentPage: {
        type: Number,
        default: 1
    }
  },
  setup(props, context) {
    const state = reactive({
        currentNum:props.currentPage,
        pages:null,
        list:null,
    })
    onMounted(()=>{
        load()
    })
    watch(props,(nweProps, oldProps)=>{
        state.currentNum = props.currentPage
        load()
    })
    function load(){
        state.pages=computed(() => Math.ceil(props.total / props.pagesize )),
        state.list = computed(() => {
            const result = []
            // 总页数小于等于5页的时候
            if (state.pages <= 5) {
                for (let i = 1; i <= state.pages; i++) {
                result.push(i)
                }
            } else {
                // 总页数大于5页的时候
                // 控制两端的省略号的有无，页码的显示个数与选中页码居中
                if (state.currentNum <= 2) {
                    for (let i = 1; i <= 5; i++) {
                        result.push(i)
                    }
                } else if (state.currentNum >= 3 && state.currentNum <= state.pages - 2) {
                    for (let i = state.currentNum - 2; i <= state.currentNum + 2; i++) {
                        result.push(i)
                    }
                } else if (state.currentNum >state. pages - 2) {
                    for (let i = state.pages - 4; i <= state.pages; i++) {
                        result.push(i)
                    }
                }
            }
            return result;
        })
    }
    
    function changePage(type) {
        // 点击上一页按钮
        if (type === false) {
            if (state.currentNum <= 1)
            return
            state.currentNum -= 1
        } else if (type === true) {
            // 点击下一页按钮
            if (state.currentNum >= state.pages)
            return
            state.currentNum += 1
        } else {
            // 点击页码
            state.currentNum = type
        }
        context.emit('changePage',state.currentNum);
    }
    const refData = toRefs(state)
    return {
        changePage,
        ...refData
    }
  }
}
</script>
<style scoped>
    .my-pagination {
        display: flex;
        justify-content: center;
        padding: 30px; 
    }
    .my-pagination a {
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        margin-right: 10px;
    }
    .my-pagination:hover {
        color: #0089D2;
    }
    .my-pagination .active {
        background: #0089D2;
        color: #fff;
        border-color: #0089D2;
    }
    .my-pagination .disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    .disabled:hover {
        color: #333;
    }
    .my-pagination span {
        margin-right: 10px;
    }
</style>