export default {
    main: {
        indexTxt: "Home",
        hotline: 'Hotline',
        address: 'Address',
        public: 'WECHAT',
        ldTxt: 'Shenzhen LDROBOT Co., LTD',
        learnMore: 'Further exploration',
        technology: 'Technology and Application',
        newTxt: 'News',
        moreNews: 'More news',
        moreCustomized: 'MORE CUSTOMIZATION',
        moreCustomizedPlan: 'MORE CUSTOMIZATION SOLUTION',
        appScenario: 'Application scenarios',
        dataDownloadCentre: 'Data Download',
        jobSearch: 'jobs Search',
        searchTxt: 'search',
        dataDownload: 'Data download',
        updateOn: 'Update',
        downloadTxt: 'download',
        headCustomer: 'IN-DEPTH COOPERATION WITH LEADING TECH COMPANIES GLOBALLY',
        advantageTxt: 'MORE THAN 5 MILLION UNITS GLOBAL SHIPMENT OF SENSORS',
        professionalTeam: 'Professional customer service and technical support team',
        processServices: 'professional process Development from product concept to mass production',
        deployment: 'Rapid deployment and project landing',
        cycle: 'Effectively shorten project cycle and reduce cost',
        salesSupport: 'sales support',
        regularlyVisit: 'Regular return visit, timely record and deal with the problems in actual use',
        serviceSystem: '24-hour service system',
        consultingServices: 'Online service system, provide high-quality consulting services',
        trainingSupport: 'Training support',
        technicalSupport: 'provide free training materials, remote operation training and other technical support during the warranty period',
        preSale: 'Support',
        toUnlock: 'Product use confusion, more skills unlock',
        serviceSupport: 'Service support',
        theSensor: 'sensor',
        theSolution: 'Top product and R&D team\n provide you with professional quality assurance, service system\nCan provide efficient and comprehensive after-sales solutions, so that you have no worries before sales',
        developers: 'Developer Consultation Email',
        assuranceSystem: 'Six quality assurance systems',
        qualitySssurance: 'Research and development of quality assurance, IQC quality assurance, supplier quality assurance, production process monitoring management, shipment inspection quality management, customer service quality management',
        contactUs: 'Contact us',
        specialLine: 'Service special line',
        cooperationEmail: 'Business Cooperation Email',
        companyAddress: 'The company address',
        userName: 'Please enter your name',
        userMobil: 'Please enter your mobile number',
        userEmail: 'Please enter email address',
        userCompanyName: 'Please enter the company name',
        userAddress: 'Please enter the company address',
        onlineMessage: 'Online message...',
        submit: 'submit',
        ldrobot: 'LDROBOT',
        certificate: 'Honorary',
        course: 'Course',
        robotPatents: 'Robot patents',
        invention: 'invention patent',
        application: 'Trademark application',
        ldrobotName: 'Shenzhen LDROBOT Co., LTD',
        partner: 'Become a partner',
        theCore: ' LDROBOT adheres to the core values of "struggle, integrity, innovation, achievement, feedback", always standing at the forefront of the robot perception industry, to achieve win-win with partners for the purpose, with great enthusiasm to empower customers, drive industry development with innovation and create infinite possibilities!',
        emailConsultation: 'EMAIL US',
        detailsTxt: 'Check the details',
        relatedNews: 'Related news',
        joinUs: 'Join us',
        intelligent: 'ROBOT PERCEPTION AND MOBILE INTELLIGENCE EXPERT',
        betterUs: 'Join us to be better',
        rightPosition: 'In the right position, you will shine',
        welfare: 'Highly competitive salary and benefits',
        activity: 'Regular activities:badminton, basketball, anniversary celebrations etc.',
        forAjob: 'Searching Job',
        screening: 'screening',
        removeTxt: 'remove',
        newJob: 'Start a new Job',
        previousPage: 'previous page',
        nextPage: 'next page',
        recruitment: 'Job postings',
        jobDetails: 'Job Details',
        ofWork: 'Place of work',
        jobRequirements: 'Job Responsibilities',
        jobDescription: 'Qualifications',
        Information: 'Contact Information',
        emailTxt: 'Email',
        sendResume: 'https://ldrobot.zhiye.com/campus',
        nameCannot: 'The name cannot be empty',
        wrongMobile: 'The phone number is incorrect, please try again',
        wrongEmail: 'The email format is incorrect, please try again',
        companyCannot: 'The company name cannot be empty',
        companyAddressCannot: 'The company address cannot be empty',
        submitSuccess: 'Submitted',
        promptTxt: 'prompt',
    }
}