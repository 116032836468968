<template>
  <div class="app-container" v-if="isRouterAlive" v-cloak>
    <Header @addHeadedr="addHeadedr" :headerNavIndex="headerNavIndex" />
    <router-view
      :navData="navData"
      @viewParent="footerNavFun"
    ></router-view>
    <div class="backBox" @click="clickBackTop">
      <img src="./assets/image/backTop.png" alt="" class="backIcon" />
    </div>
    <Footer :footerData="footerData" @footerNavFun="footerNavFun" />
  </div>
</template>

<script>
import Header from "./components/module/header.vue";
import Footer from "./components/module/footer.vue";
import { provide, reactive, toRefs, nextTick, onMounted } from "vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup(props, context) {
    const state = reactive({
      footerData: null,
      isRouterAlive: false,
      headerNavIndex: null,
      navData: null,
    });
    onMounted(() => {
      load()
      reload()
    });
    //刷新事件
    function reload() {
      state.isRouterAlive = false
      //nextTick：在下次 DOM 更新循环结束之后执行延迟回调。在修改数据之后立即使用这个方法，获取更新后的 DOM。
      setTimeout(() => {
        state.isRouterAlive = true
      }, 500)
    }
    function load() {
      $(window).scroll(function () {
        var winScrollHeight = $(document).scrollTop();
        if (winScrollHeight >= 400) {
          $(".backBox").css({ right: 1.5 + "rem" });
        } else {
          $(".backBox").css({ right: -10 + "rem" });
        }
      });
    }
    function addHeadedr(dataList) {
      state.footerData = dataList;
      state.navData = dataList;
    }
    function footerNavFun(index) {
      state.headerNavIndex = index.index;
    }
    function clickBackTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    // 向子组件以及子孙组件传递名为reload的函数，第一个参数自定义，第二个参数代表上面定义的reload()方法
    provide("reload", reload);
    const refData = toRefs(state);
    return {
      addHeadedr,
      footerNavFun,
      clickBackTop,
      ...refData,
    };
  },
};
</script>

<style>
.backBox {
  display: flex;
  align-items: center;
  text-align: center;
  width: 3.75rem;
  height: 3.75rem;
  background: #0089d2;
  border-radius: 3.75rem;
  position: fixed;
  bottom: 1.5rem;
  right: -10rem;
  z-index: 1;
  transition: 0.5s;
  cursor: pointer;
}
.backIcon {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
}
/* .wrapper-s {
  min-height: 500px;
  min-width: 100%;
  background-color: rgb(245, 245, 245);
} */
</style>
