<template>
    <div style="margin-top: 5rem;">
        <img v-if="planBanner" :src="planBanner" alt="" srcset="" class="planBanner">
        <div class="divView">
            <div class="childerNavBox">
                <ChilderNav
                    :obj="childerNav"
                    backColor="#F7F9FA"
                    @parentFun="navFun"
                    :navIndex="childeIndex" />
            </div>
            <div v-if="!showMoreDemo">
                <div v-for="(item, index) of solutionLi" :key="index" class="solutionList">
                    <div class="tab">
                        <div class="tab-top">
                            <TitleModel :titleTxt="item.name" style="margin: 4rem 0 3rem;"/>
                            <div class="listContent" v-html="item.ctx"></div>
                            <!-- <p class="listContent">{{ item.ctx }}</p> -->
                        </div>
                        <!-- <img v-if="item.image" :src="imgUrl + item.image" alt="" srcset=""
                            class="solutionListIcon childerNavBox"> -->
                    </div>
                </div>
            </div>
            <div v-else class="solutionList moreSolutionBox">
                <div class="moreDemoTitle">
                    <TitleModel :titleTxt="`${$t('main.moreCustomizedPlan')}`" />
                </div>
                <div v-for="(item, index) of moreList" :key="index" class="ulListx">
                    <div class="moreTop">
                        <img src="../../assets/image/solutionMoreIcon.png" alt="" srcset="" class="topIcon" />
                        <p class="topTitle mote">{{ item.name }}</p>
                    </div>
                    <div class="moreContent">
                        <div v-for="(items, indexs) of item.child_data" :key="indexs"
                            :class="['moreList', indexs % 2 == 0 ? 'listRight' : 'listLeft']">
                            <div class="listDetails">
                                <img :src="imgUrl + items.cover" alt="" srcset="" class="moreListIcon" />
                                <p class="moreListTitle size16">{{ items.name }}</p>
                            </div>
                        </div>
                        <div class="moreList listLeft mobileLast">
                            <div class="listDetails lastList" v-if="languageState == 'zh'">
                                更多产品 <br>
                                敬请期待
                            </div>
                            <div class="listDetails lastList" v-else>more</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import ChilderNav from '../module/childerNav.vue'
import TitleModel from '../module/titleModel.vue'
import { POST, ldImg } from '../../http/api'
import { useRoute, useRouter } from "vue-router"
import { watch, reactive, toRefs, onMounted } from "vue"
import general from "@/utils/general"
import editorCss from '@/utils/editorCss'

export default {
    components: {
        ChilderNav, TitleModel
    },
    props: ['navData'],
    setup(props) {
        const route = useRoute(),
              router = useRouter()
        const state = reactive({
            solutionLi: [],
            moreList: [],
            showMoreDemo: !1,  // 是否展示对应的更多详情
            childeIndex: null,
            childerNav: [],
            planBanner: "",
            imgUrl: ldImg(),
            languageState: window.sessionStorage.getItem('language') ?? 'zh'
        })

        onMounted(() => {
            if(props?.navData?.menu_data && props?.navData?.menu_data.length > 0) chiderFun(props?.navData?.menu_data)
            $('body,html').animate({
                scrollTop: 0
            })
        })

        // 当路由切换时 数据对应切换
        watch(() => route.query, (n, o) => {
            if (n.twoIndex) {
                state.childeIndex = n.twoIndex
                chiderFun(props?.navData?.menu_data)
                $('body,html').animate({
                    scrollTop: 0
                })
                state.languageState = window.sessionStorage.getItem('language')
            }
        })

        watch(() => props.navData, (n, o) => {
            if(n.menu_data) {
                state.childeIndex = n.twoIndex
                chiderFun(n?.menu_data)
            }
        })

        // 二级菜单栏数据
        function chiderFun(data) {
            // const num = window.sessionStorage.getItem('topNavIndex') == 0 ? 0 : window.sessionStorage.getItem('topNavIndex') - 1 ?? 0
            const childerIndex = window.sessionStorage.getItem('childerNavIndex') || 0
            if (data && data[1]) {
                state.childerNav = data[1].module_data
                state.childeIndex = childerIndex
                navListData()
            }
        }

        // 点击切换下标
        function navFun(index) {
            state.childeIndex = index
            navListData()
        }

        // 获取对应的二级菜单下的数据
        function navListData() {
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            state.showMoreDemo = state.childerNav[state.childeIndex].id ? !1 : !0
            if (state.showMoreDemo) customPlan()
            else {
                POST({
                    "language": languageType == 'zh' ? 0 : 1,
                    "solution_id": state.childerNav[state.childeIndex].id
                }, 'get_solution').then((msg) => {
                    if (msg.code == 20000) {
                        const { banner, content } = msg.data?.data_list[0]?.fields
                        state.planBanner = banner ? state.imgUrl + banner : ''
                        content.map(item => {
                            item['ctx'] = general.isMobileFn() ? item['mobile_content'] ? editorCss.format(item['mobile_content']) : '' : item['description'] ? editorCss.format(item['description']) : ''
                            return item
                        })
                        state.solutionLi = content
                    } else alert(msg.code)
                })
            }
        }

        // 获取对应ai感知方案的i18n
        function customPlan() {
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            POST({ "language": languageType == 'zh' ? 0 : 1, }, 'get_custom').then((msg) => {
                if (msg.code == 20000) {
                    state.planBanner = state.imgUrl + msg.data.banner
                    state.moreList = msg.data.custom_plan_data
                } else alert(msg.code)
            })
        }
        const refData = toRefs(state)
        return {
            navFun, chiderFun, customPlan,
            ...refData,
        }
    }
}
</script>
<style scoped>
.lastList {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ADADAD;
    font-size: 1.5rem;
    line-height: 3rem;
}

.mobileLast {
    display: none;
}

.planBanner,
.solutionListIcon {
    width: 100%;
    display: block;
}

/* .solutionList { padding-top: 2rem; } */
.ulListx {
    padding-top: 5rem;
}


.tab {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 8rem;
}

.tab-top {
    /* position: absolute; */
    margin-bottom: 2rem;
}

.listContent {
    padding: 0 4rem;
    margin: auto;
    margin-top: 2rem;
    color: #3F3F3F;
    text-align: center;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    color: #222629;
    /* max-height: 30rem; */
}

.moreTop {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topIcon {
    width: 1.31rem;
    height: 2.06rem;
}

.topTitle {
    color: #585858;
    font-size: 1.25rem;
    margin-left: 1.37rem;
}

.moreContent {
    display: flex;
    flex-direction: row;
    margin-top: 3.75rem;
}

.moreList {
    flex: 1;
    transition: .5s;
    padding: .5rem .8rem;
    border-radius: .5rem;
}

.moreList:hover {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, .3)
}

.moreListIcon {
    width: 100%;
    display: block;
}

.moreListTitle {
    text-align: center;
    color: #585858;
    margin-top: 2.12rem;
}

.moreList:hover .moreListTitle {
    color: #0089D2;
    font-weight: bold;
}
.moreDemoTitle {
    margin: 4rem 0 3rem;
}
</style>