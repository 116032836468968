<template>
  <div>
    <img :src="ldbanner" alt="" srcset="" class="informationbanner">
    <div class="divView" :style="{background: '#F7F9FA'}">
        <div class="informationBox"
        >
            <div v-for="(item,index) of newList" :key="index" 
                :class="['informationList',{'border-bottom':index!=newList.length-1}]"
                @click="this.$router.push({name:'NewDetails',query:{id:item.pk}})"
            >
                <img :src="imgUrl+item.fields.cover" alt="" srcset="" class="listIcon" />
                <div class="rightContent">
                    <div>
                        <h1 class="contentTitle">{{item.fields.title}}</h1>
                        <p class="contentIcon"></p>
                        <p class="contentTxt">{{item.fields.introduction}}</p>
                    </div>
                    <div class="informationBottom">
                        <img src="../../assets/image/time.png" alt="" srcset="" class="timeIcon" />
                        <p class="timeTxt">{{time(item.fields.create_time)}}</p>
                        <span class="btnTxt">{{$t('main.detailsTxt')}}</span>
                        <img src="../../assets/image/right.jpg" alt="" srcset="" class="rightIcon" />
                    </div>
                </div>
            </div>
        </div>
        <PagePlugin
            :total="total"
            :pagesize="pageSize"
            :currentPage="pageNum"
            @change-page="changePage"
        />
    </div>
  </div>
</template>
<script>
import {POST,ldImg,changeDateFormat} from '../../http/api'
import PagePlugin from '../module/PagePlugin.vue'
import { onMounted,reactive,toRefs,watch} from "vue";
export default {
    components: {
        PagePlugin,
    },
    props: ['navData'],
    setup(props, context) {
        const state = reactive({
            ldbanner:null,
            newList:[],
            imgUrl:ldImg(),
            total:null,
            pageSize:8,
            pageNum:1,
        })
        onMounted(()=>{
            bannerImg()
            load()
        })
        watch(() =>props.navData,(nweProps, oldProps) => {
            bannerImg()
        })
        function load(){
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            POST({
                "language": languageType=='zh'?0:1,
                "list_query": {
                    "name": "", 
                    "page": state.pageNum, 
                    "limit":state.pageSize
                }
            },'get_news').then((msg)=>{
                if(msg.code==20000){
                    const {total,news_list} = msg.data
                    state.newList = news_list
                    state.total = total
                }else{
                    alert(msg.code)
                }
            })
        }
        function bannerImg(){
            if(!props.navData) return
            const {company_news_banner} =props.navData
            state.ldbanner = ldImg()+company_news_banner
        }
        function time(time){
            return changeDateFormat(time)
        }
        function changePage(currentPage){
            state.pageNum = currentPage
            load()
        }
        const refData = toRefs(state)
        return {
            time,changePage,
            ...refData
        }
    }
}
</script>
<style scoped>
    .informationbanner{
        width: 100%;
        display: block;
    }
    .informationBox{
        padding:.6875rem 2.375rem;
        background: #fff;
        border-radius: 5px;
        position:relative;
        z-index: 1;
        margin-top: -9.375rem;
    }
    .informationList{
        display: flex;
        flex-direction: row;
        padding:2.375rem 0; 
        border-color: #E3E3E3;
        cursor: pointer;
    }
    .informationList:hover .contentTitle{
        color:#0089D2;
        font-weight:bold;
    }
    .listIcon{
        width: 28.2rem;
        height: 16.125rem;
        overflow: hidden;
    }
    .rightContent{
        margin-left: 2.625rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 28.2rem);
    }
    .contentTitle{
        color:#4C4948;
        font-size: 1.25rem;
        font-family: 'OPPOSans-M';
    }
    .contentIcon{
        width: 1.8125rem;
        height:.125rem ;
        background: #0089D2;
        margin-top: 2.5625rem;
    }
    .contentTxt{
        color: #585858;
        font-size: .875rem;
        margin-top: 2.5rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }
    .informationBottom{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1.875rem;
    }
    .timeIcon{
        width: .8125rem;
        height: .8125rem;
    }
    .timeTxt{
        flex: 1;
        margin-left: .625rem;
        color:#A6A6A6;
        font-size: .875rem;
    }
    .btnTxt{
        color:#A6A6A6;
        font-size: .875rem;
    }
    .btnTxt:hover{
        color:#0089D2;
    }
    .rightIcon{
        width: .4375rem;
        height: .8125rem;
        margin-left: .5625rem;
    }
</style>