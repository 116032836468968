<template>
  <div>
    <span class="btnBtn border"
        @click="moreBtnFun"
        @mouseenter="moveFun()"
        @mouseleave="moveOutFun()"
    >
        <text class='btnTxt size16'>{{title_Txt}}</text>
        <img src="../../assets/image/right.jpg" alt="" sizes="" srcset="" class="btnImg">
    </span>
  </div>
</template>
<script>
/* eslint-disable */
// eslint-disable-next-line vue/no-setup-props-destructure
import {onMounted,reactive,toRefs,watch  } from 'vue';
export default {
    name: 'LdBotton',
    props:['titleTxt','more'],
    setup(props,context) {
        const state = reactive({
            title_Txt : props.titleTxt
        })
        watch(props, (nweProps, oldProps) => {
            if(nweProps.titleTxt){
                state.title_Txt = nweProps.titleTxt
            }
        })
        function moreBtnFun(){
            context.emit('more')
        }
        function moveFun(){
            $('.btnImg').addClass('arrowSS')
        }
        function moveOutFun(){
            $('.btnImg').removeClass('arrowSS')
        }
        const refData = toRefs(state)
        return {
            moreBtnFun,moveFun,moveOutFun,
            ...refData
        }
    }
}
</script>
<style scoped>
    .btnBtn{
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items:center;
        border-color:  #0089D2 !important;
        padding: .5rem .75rem;
        border-radius: 4px;
        cursor: pointer;
    }
    .btnTxt{
        color:#0089D2;
    }
    .btnImg{
        height: .9375rem;
        width: .5rem;
        margin-left:.5rem ;
    }
    .arrowSS {
      animation: bounce-inSS 2s infinite; /* 启动动画特效 */
    }
    @keyframes bounce-inSS {
      0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
      }
      40% {
        transform: translateX(4px);
      }
      60% {
        transform: translateX(2px);
      }
    }
</style>