<template>
  <div style="margin-top: 5rem">
    <img :src="ldbanner" alt="" srcset="" class="cooperationbanner" >
    <div class="divView cooperationBox">
      <TitleModel
        :titleTxt="`${$t('main.partner')}`"
      />
      <p class="details">{{$t('main.theCore')}}</p>
      <div class="inputBox border-bottom">
        <UserInput/>
      </div>
      <p class="proTxt">{{$t('main.emailConsultation')}}</p>
      <p class="proTxt ttt">{{$t('main.cooperationEmail')}}</p>
      <div class="bottomBox">
        <span class="bottomContent">
          <img src="../../assets/image/contact2.jpg" alt="" srcset="" class="bottomIcon" >
          <p>{{businessEmail}}</p>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import TitleModel from '../module/titleModel.vue'
import UserInput from '../module/userInput.vue'
import {ldImg} from '../../http/api'
import { onMounted,reactive,toRefs,watch} from "vue";
export default {
  components: {
    TitleModel,UserInput
  },
  props:['navData'],
  setup(props, context) {
    const state = reactive({
      ldbanner:null,
      businessEmail:null,
    })
    onMounted(()=>{
      bannerImg()
      $('body,html').animate({
          scrollTop: 0
      })
    })
    watch(() =>props.navData,(nweProps, oldProps) => {
      bannerImg()
    })
    function bannerImg(){
      if(!props.navData) return
      const {cooperation_banner,company_info} =props.navData
      state.ldbanner = ldImg()+cooperation_banner
      state.businessEmail = company_info[0].business_email
    }
    const refData = toRefs(state)
    return {
      ...refData,
    }
  }
}
</script>
<style scoped>
  .cooperationbanner{
    width: 100%;
    display: block;
  }
  .bottomBox{
    text-align: center;
  }
  .bottomContent{
    margin: auto;
    display: block;
    width: 23.625rem;
    padding:1.3125rem 0;
    margin-top: 1.25rem;
    border: 1px solid #ddd;
    color: #585858;
  }
  .bottomIcon{
    width:2.375rem ;
    height: 1.875rem;
    margin: 1.25rem 0;
  }
  .proTxt{
    text-align: center;
    color: #585858;
    /* font-size: .875rem; */
    margin-top: 1.625rem;
  }
  .cooperationBox{
    margin-top: 4.9375rem;
  }
  .details{
    color: #585858;
    font-size: 1.25rem;
    text-align: center;
    margin-top: 2.875rem;
    white-space: pre-wrap;
    line-height: 3rem;
  }
  .inputBox{
    margin-top: 4.3125rem;
    padding-bottom: 2.875rem;
    margin-bottom: 4.375rem;
    border-color: #DEDEDE;
  }
</style>