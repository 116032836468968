import request from "./request";
import env from "./env";
export function GET(data={},url) {
    return request({
        url: env.envUrl[url], 
        method: "GET", 
        data
    });
}
export function POST(data={},url) {
    return request({
        url: env.envUrl[url],
        method: "POST",
        data
    })
}
export function ldImg(){
    // return 'http://192.168.160.11:3333/images/'
    return 'https://www.ldrobot.com/images/'
}
export function changeDateFormat(time, format = 'YYYY-MM-DD') { //YYYY-MM-DD hh:mm:ss
    if(!time) return '';
    var datetime = new Date(time);
    var o = {
        'Y+': datetime.getFullYear(),
        "M+": datetime.getMonth() + 1,
        "D+": datetime.getDate(),
        "h+": datetime.getHours(),
        "m+": datetime.getMinutes(),
        "s+": datetime.getSeconds(),
        "q+": Math.floor((datetime.getMonth() + 3) / 3),
        "S": datetime.getMilliseconds()
    };
    if (/(Y+)/.test(format)) format = format.replace(RegExp.$1, (datetime.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
        return format;
}
export function winResize(){
    return $(window).width() > 870?false:true
}