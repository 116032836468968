export default {
    envUrl:{
        get_test:'api/utility_category/utility_info',//通用数据
        get_home:'api/home/get_home_info',//首页数据
        get_sensor:'api/sensor/get_sensor_list',//传感器列表
        get_sensorInfo:'/api/sensor/get_sensor_info',//产品详情
        get_solution:'api/solution/get_solution_info',//解密方案详情
        get_custom:'api/solution/get_custom_plan',//更多定制化方案
        get_application:'/api/application/get_application_info',//应用领域
        get_data:'/api/data_center/get_data_list',//下载中心左侧列表
        get_file:'/api/data_center/get_file_list',//下载中心右侧下载文件
        get_service_support: '/api/data_center/get_service_support', //服务技术 - 技术支持
        get_sales_service: '/api/data_center/get_sales_service',// 服务技术 - 售前 / 售后
        get_about:'/api/about_us/get_about_us_data',//关于我们
        get_news:'/api/about_us/get_news_list',//新闻资讯列表
        get_newsInfo:'/api/about_us/get_news_info',//新闻详情
        get_jobs_propaganda: '/api/about_us/get_jobs_propaganda',// 加入我们 - 上面部分
        get_jobType:'/api/about_us/get_job_type_list',//加入我们 工作筛选
        get_jobInfo:'/api/about_us/get_job_info_list',//加入我们信息列表
        submit_leave:'api/utility_category/submit_leave_message',//提交反馈
    }
}