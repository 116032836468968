<template>
    <div style="margin-top: 5rem;">
        <img :src="serviceBanner" alt="" srcset="" class="serviceBanner">
        <div class="divView" :style="{ background: '#F0F1F2' }">
            <div class="serviceTitleBox">
                <TitleModel :titleTxt="`${$t('main.dataDownloadCentre')}`" />
            </div>
            <div class="dataBox">
                <div class="dataListLeft">
                    <div class="dataSearch border-bottom">
                        <img src="../../assets/image/saveIcon.jpg" alt="" srcset="" class="saveIcon" />
                        <input type="text" :placeholder="$t('main.searchTxt')" class="searchInput" @input="onAgeInput" />
                    </div>
                    <div class="dataTypeList" v-for="(item, index) of typeList" :key="index">
                        <div class="typeTopBox" @click="clickListFun(index)">
                            <span class="typeTitle">{{ item.type_name }}</span>
                            <img src="../../assets/image/bottomIcon.png" alt="" srcset="" class="bottomIcon" />
                        </div>
                        <ul class="typeUl">
                            <li v-for="(items, indexs) of item.child_data" :key="indexs" class="typeLi"
                                @click="getFile(items.id, items.sensor_name)">{{ items.sensor_name }}</li>
                        </ul>
                    </div>
                </div>
                <div class="dataContentRight">
                    <div class="contentBox">
                        <h1 class="contentTitle border-bottom">{{ $t('main.dataDownload') }}</h1>
                        <div v-for="(item, index) of downloadList" :key="index"
                            :class="['downloadUl', { 'border-bottom': index < downloadList.length - 1 }]">
                            <div class="downloadLeft">
                                <h1 class="downloadTitle">{{ title }} - {{ item.name }}</h1>
                                <p class="downloadTiime">{{ $t('main.updateOn') }}：{{ timeData(item.create_time) }}</p>
                            </div>
                            <div class="downloadBtn border" @click="down(item.data)">
                                <img src="../../assets/image/download.png" alt="" srcset="" class="downloadIcon" />
                                <span class="btnTxt">{{ $t('main.downloadTxt') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import TitleModel from '../module/titleModel.vue'
import { POST, ldImg, changeDateFormat } from '../../http/api'
import { onMounted, watch, reactive, toRefs } from "vue";

export default {
    components: {
        TitleModel,
    },
    props: ['navData'],
    setup(props, context) {
        const state = reactive({
            typeList: [],
            downloadList: [],
            serviceBanner: null,
            title: ''
        })
        onMounted(() => {
            getData()
            bannerImg()
            $('body,html').animate({
                scrollTop: 0
            })
        })
        watch(() => props.navData, (nweProps, oldProps) => {
            bannerImg()
        })
        function getData(val = '') {
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            POST({ "language": languageType == 'zh' ? 0 : 1, "name": val }, 'get_data').then((msg) => {
                if (msg.code == 20000) {
                    const data = msg.data.download_data
                    let savData = []
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].child_data.length != 0) {
                            savData.push(data[i])
                        }
                    }
                    state.typeList = savData
                    getFile(savData[0].child_data[0].id, savData[0].child_data[0].sensor_name)
                } else {
                    alert(msg.code)
                }
            })
        }
        function bannerImg() {
            if (!props.navData) return
            const { service_banner } = props.navData
            state.serviceBanner = ldImg() + service_banner
        }
        function getFile(id, name) {
            POST({ "product_id": id }, 'get_file').then((msg) => {
                if (msg.code == 20000) {
                    state.downloadList = msg.data.data
                    state.title = name
                } else alert(msg.code)
            })
        }
        function clickListFun(index) {
            const demo = $('.dataTypeList').eq(index)
            const demoList = demo.find('.bottomIcon')
            if (demoList.hasClass('bottomIconTranDeg')) {
                demoList.removeClass('bottomIconTranDeg')
            } else {
                demoList.addClass('bottomIconTranDeg')
            }
            demo.find('.typeUl').slideToggle()
            demo.siblings().find('.typeUl').slideUp()
            demo.siblings().find('.bottomIcon').removeClass('bottomIconTranDeg')
        }
        // 文件下载
        function down(fileUrl) {
            const url = ldImg() + fileUrl
            let a = document.createElement('a')
            a.target = "_blank"
            a.href = url
            a.click()
        }
        function timeData(time) {
            return changeDateFormat(time)
        }
        function onAgeInput(e) {
            getData(e.target.value)
        }
        const refData = toRefs(state)
        return {
            clickListFun, getData, timeData, down, getFile, onAgeInput,
            ...refData,
        }
    }
}
</script>
<style scoped>
.serviceBanner {
    width: 100%;
    display: block;
}

.downloadUl {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.43rem;
    padding-bottom: .43rem;
    border-color: #C6C6C6;
}

.downloadLeft {
    flex: 1;
}

.downloadTitle {
    color: #3F3F3F;
    font-size: .875rem;
}

.downloadTiime {
    color: #C6C6C6;
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    transform: scale(.625);
    font-size: .625rem;
    margin-top: .125rem;
}

.downloadBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: #AAAAAA;
    border-radius: .96rem;
    padding: .56rem 1.25rem;
    cursor: pointer;
}

.downloadBtn:hover .btnTxt {
    color: #0089D2;
}

.downloadIcon {
    width: .6875rem;
    height: .8125rem;
}

.btnTxt {
    color: #8C8C8C;
    font-size: .875rem;
    margin-left: .56rem;
}

.contentTitle {
    color: #4C4C4C;
    font-size: 1.25rem;
    padding: 1.18rem 0;
    border-color: #DEDEDE;
    font-family: 'OPPOSans-M';
}

.contentBox {
    border-radius: .3125rem;
    background: #fff;
    padding: .68rem 3.125rem;
    padding-bottom: 2rem;
    height: 100%;
}

.dataContentRight {
    flex: 1;
    margin-left: 1.25rem;
}

.typeUl {
    display: none;
}

.typeLi {
    padding: 0.625rem 0 0.625rem 2.93rem;
    color: #4C4C4C;
    font-size: .875rem;
    border-top-left-radius: .3125rem;
    border-bottom-left-radius: .3125rem;
}

.typeLi:hover {
    background: #F0F1F2;
    cursor: pointer;
}

.typeLi:active {
    background: #F0F1F2;
}

.dataTypeList {
    padding: 0.655rem 0 0.655rem 0;
    cursor: pointer;
}

.typeTopBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bottomIcon {
    width: .625rem;
    height: .375rem;
    margin-right: .62rem;
    transition: .2s;
}

.bottomIconTranDeg {
    transform: rotate(-180deg)
}

.typeTitle {
    flex: 1;
    color: #4C4C4C;
    font-size: .875rem;
}

.serviceTitleBox {
    padding: 5rem 0 3.06rem 0;
}

.dataBox {
    display: flex;
    flex-direction: row;
}

.dataListLeft {
    width: 16.43rem;
    border-radius: .3125rem;
    background: #fff;
    padding-left: .62rem;
}

.dataSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: #DEDEDE;
    margin-bottom: .655rem;
}

.saveIcon {
    width: 1rem;
    height: 1rem;
    display: block;
}

.searchInput {
    width: 100%;
    color: #343434;
    font-size: .875rem;
    padding: 1.125rem 1.125rem;
    border: none
}</style>