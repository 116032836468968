<template>
    <div class="applyBox">
        <div class="topContent">
            <h1 class="applyTitle">{{$t('main.recruitment')}}</h1>
            <img src="../../../assets/image/colse.png" class="colseIcon" @click="colseFun">
        </div>
        <div class="applyContent">
            <h1 class="applyDetailsTxt border-bottom">{{$t('main.jobDetails')}}</h1>
            <div class="applyLi border-bottom">
                <h1 class="liTxt">{{$t('main.ofWork')}} :</h1>
                <p class="liDetails size16">{{addressName}}</p>
            </div>
            <div class="applyLi border-bottom">
                <h1 class="liTxt">{{$t('main.jobRequirements')}} :</h1>
                <p class="liDetails size16">{{requirements}}</p>
            </div>
            <div class="applyLi border-bottom">
                <h1 class="liTxt">{{$t('main.jobDescription')}} :</h1>
                <p class="liDetails size16">{{description}}</p>
            </div>
            <div class="applyLi rowDisplay">
                <h1 class="liTxt">{{$t('main.Information')}} :</h1>
                <p class="liDetails size16">{{$t('main.sendResume')}}</p>
            </div>
            <!-- <div class="applyLi rowDisplay">
                <h1 class="liTxt">{{$t('main.emailTxt')}} :</h1>
                <p class="liDetails size16"> {{hrEmail}}</p>
            </div> -->
        </div>
    </div>
</template>
<script>
import { onMounted,watch,reactive,toRefs} from "vue";

export default {
    components: {

    },
    props:['objData'],
    setup(props, context) {
        const {objData} = props
        const {fields,hrEmail} = objData
        const {address_names,job_requirements,job_description} = fields;
        const state = reactive({
            
            addressName:address_names.toString().replace(/,/g," | "),
            requirements:job_requirements,
            description:job_description,
            hrEmail,
        })
        function colseFun(){
            $('.applyBox').animate({width:0,height:0},()=>{
                context.emit('hideMask')
            })
        }
        onMounted(()=>{
            $('.applyBox').animate({width:'50%',height:'70%'})
        })
        const refData = toRefs(state)
        return {
            colseFun,
            ...refData
        }
    }
}
</script>
<style scoped>
    .applyBox{
        width: 0;
        height: 0;
        background: #fff;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        display: flex;
        flex-direction:column;
    }
    .applyLi{
        padding:0 1rem;
    }
    .rowDisplay{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .rowDisplay p{
        flex: 1;
        padding: 0;
        margin-left: 1rem;
    }
    .liDetails{
        color: #666666;
        /* text-align: justify; */
        white-space: pre-wrap;
        padding-bottom: 1rem;
        line-height:2.5rem
    }
    .liTxt{
        padding: 1rem 0;
        font-size: 1.25rem;
        font-weight: bold;
        color: #343434;
    }
    .applyDetailsTxt{
        color:#0089D2;
        font-weight:bold;
        font-size:1.5625rem ;
        padding: 2rem 0 ;
    }
    .applyContent{
        flex: 1;
        overflow-y: scroll;
        padding: 0 4rem;
    }
    .applyContent::-webkit-scrollbar {
        width: 4px;    
    }
    .applyContent::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: rgba(0,0,0,0.2);
    }
    .applyContent::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 0;
        background: rgba(0,0,0,0.1);
    }
    .topContent{
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #F7F9FA;
        padding: 1.5rem 1rem;
    }
    .applyTitle{
        flex: 1;
        font-size: 1.4rem;
        color: #343434;
        font-weight: bold;
    }
    .colseIcon{
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
    }
</style>