<template><div id="map" :style="{ width: 54.06 + 'rem', height: 27.43 + 'rem' }"></div></template>
<script>

import { baiduMap } from "./baiduMap";
import { watch, onMounted, nextTick } from "vue";
import { useI18n } from 'vue-i18n'
export default {
    props: {
        // 传入需要定位的经纬度
        coordinates: {
            default: 0,
        },
        width: {
            type: Number,
            default: 865 / 16,
        },
        height: {
            type: Number,
            default: 439 / 16,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        onMounted(() => {
            demo()
        });
        function demo() {
            if (!props.coordinates) return
            nextTick(() => {
                baiduMap().then((mymap) => {
                    // 创建地图实例
                    let map = new BMap.Map("map"); // 创建地图实例
                    let point = new BMap.Point(props.coordinates[0], props.coordinates[1]); // 创建点坐标

                    let content = `<div><div class="map-house-center"><label>
                        ${t('main.ldrobotName')}
                        </label><br>
                        <span class='icon-list-icon'></span>
                        </div></div>`;
                    let label = new BMap.Label(content, {
                        // 创建文本标注
                        position: point, // 设置标注的地理位置
                        // offset: window.BMapGL.Size(10, 20), // 设置标注的偏移量
                    }); // 创建文本标注对象
                    map.addOverlay(label); // 将标注添加到地图中
                    label.setStyle({
                        // 设置label的样式
                        color: "red",
                        fontSize: "10px",
                        border: "1px solid #fff",
                        textAlign: "center",
                    });
                    label.setStyle({ border: "0 solid red" });
                    map.centerAndZoom(point, 15); //设置缩放级别 // 初始化地图，设置中心点坐标和地图级别
                    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
                    map.addEventListener("click", function (e) {
                        //   let a = JSON.parse(e)
                        console.log("点击的经纬度：" + e.point.lng + "," + e.point.lat); //
                        emit("update:longitude", e.point.lng);
                        emit("update:latitude", e.point.lat);
                    });
                });
            });
        }
        watch(() => props.coordinates, (nweProps, oldProps) => {
            demo()
        })
    },
};
</script>
<style>
.map-house-center {
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    background-color: #f5a623;
    padding: 2px 10px;
    border-radius: 6px;
    color: #fff;
    text-align: center;
}
</style>

