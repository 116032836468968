export default {
    main: {
        indexTxt: "首页",
        hotline: '全国热线',
        address: '地址',
        public: '官方公众号',
        ldTxt: '2021深圳乐动机器人股份有限公司',
        learnMore: '了解更多',
        technology: '技术与应用',
        newTxt: '新闻资讯',
        moreNews: '更多新闻',
        moreCustomized: '更多定制化',
        moreCustomizedPlan: '更多定制化方案',
        appScenario: '应用场景',
        dataDownloadCentre: '资料下载中心',
        jobSearch: '搜索职位',
        searchTxt: '搜索',
        dataDownload: '资料下载',
        updateOn: '更新于',
        downloadTxt: '下载',
        headCustomer: '服务全行业头部客户',
        advantageTxt: '累计出货量超过500万pcs强大的供应链优势',
        professionalTeam: '专业的客户服务与技术支持团队',
        processServices: '从产品概念到产品量产专业的全流程服务',
        deployment: '快速部署、项目落地',
        cycle: '有效缩短项目周期降低成本',
        salesSupport: '售后支持',
        regularlyVisit: '定期回访，及时记录处理实际使用中的问题',
        serviceSystem: '24小时服务体系',
        consultingServices: '在线服务体系，积极响应客户诊断并排除故障, 提供高质量咨询服务',
        trainingSupport: '培训支持',
        technicalSupport: '质保期内提供免费培训资料提供产品远程操作培训等售后技术支持工作',
        preSale: '售前售后',
        toUnlock: '产品使用解惑，更多技能解锁',
        serviceSupport: '服务支持',
        theSensor: '乐动传感器',
        theSolution: '顶尖的产品及研发团队\n将为您提供专业的质保、完善的服务体系\n能够提供高效全面的售后解决方案，让您售前无忧',
        developers: '开发者咨询邮箱',
        assuranceSystem: '六大质保体系',
        qualitySssurance: '研发质保、IQC 质保、供应商质保、生产过程监控管理出货检验质量管理、客户服务质量管理',
        contactUs: '联系我们',
        specialLine: '服务专线',
        cooperationEmail: '商务合作邮箱',
        companyAddress: '公司地址',
        userName: '请输入姓名',
        userMobil: '请输入手机号',
        userEmail: '请输入邮箱',
        userCompanyName: '请输入公司名称',
        userAddress: '请输入公司地址',
        onlineMessage: '在线留言...',
        submit: '提交',
        ldrobot: '乐动LDROBOT',
        certificate: '荣誉资质',
        course: '发展历程',
        robotPatents: '机器人领域专利',
        invention: '发明专利',
        application: '商标申请',
        ldrobotName: '深圳乐动机器人有限公司',
        partner: '成为合作伙伴',
        theCore: '乐动始终坚持“奋斗、诚信、创新、成就、反馈”的核心价值观\n创新驱动行业发展，以与合作伙伴实现双赢为宗旨，以极大的热忱赋能客户',
        emailConsultation: '任何问题欢迎邮件咨询',
        detailsTxt: '查看详情',
        relatedNews: '相关新闻',
        joinUs: '加入我们',
        intelligent: '机器人感知与移动智能专家',
        betterUs: '加入乐动成就更好的我们',
        rightPosition: '合适的职位，你将大放异彩',
        welfare: '极具竞争力薪酬与福利',
        activity: '定期设有羽毛球、篮球、周年庆等活动',
        forAjob: '搜索职位',
        screening: '筛选',
        removeTxt: '清除',
        newJob: '开启新的工作',
        previousPage: '上一页',
        nextPage: '下一页',
        recruitment: '招聘信息',
        jobDetails: '职位详情',
        ofWork: '工作地点',
        jobRequirements: '工作职责',
        jobDescription: '任职资格',
        Information: '联系方式',
        emailTxt: '邮箱',
        sendResume: 'https://ldrobot.zhiye.com/campus',
        nameCannot: '姓名不能为空',
        wrongMobile: '手机号码有误，请重新输入',
        wrongEmail: '邮箱格式有误，请重新输入',
        companyCannot: '公司名称不能为空',
        companyAddressCannot: '公司地址不能为空',
        submitSuccess: '提交成功',
        promptTxt: '提示',
    }
}