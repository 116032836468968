<template>
    <div class="contentDetails" v-html="newsContent" ></div>
</template>
<script>
import {POST,ldImg} from '../../http/api'
import { useRoute } from "vue-router"
import { onMounted,reactive,toRefs, watch } from "vue"
import editorCss from '@/utils/editorCss'
import general from "@/utils/general"

export default {
    setup(props, context) {

        const $route = useRoute();
        const state=reactive({
            sensor_name: $route.query.sensor_name ?? '',
            newsContent:null
        })
        
        watch(() => $route.query.sensor_name, (n, o) => {
            state.sensor_name = n
            load()
        })
        onMounted(()=>{
            $('body,html').animate({
                scrollTop: 0
            })
            load()
        })
        function load(){
            state.newsContent = null
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            if(!state.sensor_name) return false
            POST({ 
                "language": languageType=='zh'?0:1,
                "sensor_name": state.sensor_name
            },'get_sensorInfo').then((msg)=>{
                if(msg.code==20000){
                    const { content, mobile_content } = msg?.data?.sensor_product_info
                    const ctx = general.isMobileFn() ? mobile_content : content
                    state.newsContent = ctx ? editorCss.format(ctx) : ''
                    console.log(content)
                }else{
                    alert(msg.code)
                }
            })
        }
        const refData = toRefs(state)
        return {
            load,
            ...refData,
        }
    }
}
</script>
<style scoped>
.contentDetails{
    min-height: 5.5rem;
}
    .contentDetails >>>img{
        width: 100%;
        display: block;
    }
</style>