<template>
    <div style="margin-top: 5rem;">
        <img :src="ldbanner" alt="" srcset="" class="ldbanner">
        <div class="divView aboutBox" :style="{ background: '#fff' }">
            <TitleModel :titleTxt="`${$t('main.ldrobot')}`" />
            <div class="introduceBox">
                <div class="introduceLeft">
                    <div class="advantage" v-for="(item, index) of advantageList" :key="index">
                        <img src="../../assets/image/solutionMoreIcon.png" alt="" srcset="" class="advantageIcon" />
                        <p class="advantageTxt">{{ item }}</p>
                    </div>
                    <p class="period size16" v-html="companyIntroduce"></p>
                </div>
                <div class="introduceRight" @click="playVideo()" data-toggle="modal" data-target="#staticBackdrop">
                    <div class="playBox" :style="{ backgroundImage: 'url(' + playBagImg + ')', }">
                        <img src="../../assets/image/play.jpg" alt="" srcset="" class="playIcon" />
                    </div>
                    <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
                        aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <video class="videoIcon" id="video" :src="movie">
                                        <source />
                                        您的浏览器不支持 HTML5 video 标签。
                                    </video>
                                    <div class="playBox" v-if="playBagImg"
                                        :style="{ backgroundImage: 'url(' + playBagImg + ')', }" v-show="!isplay">
                                        <img src="../../assets/image/play.jpg" alt="" srcset="" class="playIcon" />
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="honor">
            <TitleModel :titleTxt="`${$t('main.certificate')}`" />
            <p class="divView honorContent">{{ companyHonor }}</p>
            <div class="honorBox">
                <img :src="honorBanner" alt="" srcset="" class="ldbanner" />
                <div class="honorUl">
                    <div class="divView ulBox">
                        <ul class="ulList">
                            <li class="list" v-for="(item, index) of patentList" :key="index">
                                {{ item.count }}+
                                <p class="listTxt size16">{{ item.description }}</p>
                            </li>
                        </ul>
                        <ul class="ulList">
                            <li class="list" v-for="(item, index) of certificateList" :key="index">
                                <img :src="imgUrl + item.cover" alt="" srcset="" class="certificateIcon" />
                                <p class="listTxt size16">{{ item.name }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="course">
            <img :src="courseBanner" alt="" srcset="" class="ldbanner" />
            <div class="honorUl">
                <div class="divView ulBox">
                    <h1 class="courseTitle">
                        {{ $t('main.course') }}
                        <i class="course_i"></i>
                    </h1>
                    <div class="courseBannerBox" @mouseenter="bannerTimeStop()" @mouseleave="setIntervalFun()">
                        <img src="../../assets/image/bannerLeft.png" class="courseBannerIcon bannerLeft"
                            @click="bannerRight(-1)" />
                        <div class="bannerBox">
                            <div class="bannerUl">
                                <div v-for="(item, index) of courseBannerList" :key="index" class="bannerList"
                                    :style="{ width: listWidth() + 'px' }" @mouseenter="imgBanner(index)">
                                    <span :class="['bannerTime', leftIndex == index ? 'selTxt' : '']">{{ item.year }}</span>
                                    <span :class="['bannerListIcon', leftIndex == index ? 'selIcon' : '']"></span>
                                    <!-- <img :src="leftIndex == index ? require('../../assets/image/point2.jpg') : require('../../assets/image/point1.jpg')"
                                        alt="" srcset="" @mouseenter="imgBanner(index)"
                                        :class="['bannerListIcon', { selIcon: leftIndex == index }]" /> -->
                                </div>
                            </div>
                        </div>
                        <img src="../../assets/image/bannerRight.png" alt="" srcset="" class="courseBannerIcon bannerRight"
                            @click="bannerRight(1)" />
                    </div>
                    <div class="courseContentBox" v-if="courseListData">
                        <div class="contentUl">
                            <h1 class="listTitle">{{ courseListData.year }}</h1>
                            <div class="contentLi">
                                <div class="listContent" v-for="(item, index) of courseListData.course_info_list"
                                    :key="index">
                                    <img src="../../assets/image/solutionMoreIcon.png" alt="" srcset="" class="listIcon" />
                                    <p class="listDateils size16">{{ item }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact">
            <TitleModel :titleTxt="`${$t('main.contactUs')}`" />
            <div class="contactBox">
                <div class="divView contactTabel">
                    <div class="contactUl ulLeft">
                        <div v-for="(item, index) of addressList" :key="index"
                            :class="['contactAddress', { hideMargin: index == addressList.length }]">
                            <div class="contactLeftIcon"></div>
                            <div class="addressContent">
                                <h1 class="addressTitle">{{ item.address_name }}</h1>
                                <p class="addressDetails">{{ item.address_info }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="contactUl ulRight">
                        <MapDemo :coordinates="coordinates" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TitleModel from '../module/titleModel.vue'
import MapDemo from '../map/mapDemo.vue'
import { POST, ldImg } from '../../http/api'
import { onMounted, reactive, toRefs, watch } from "vue"
import { useI18n } from 'vue-i18n'
import editorCss from '@/utils/editorCss'

export default {
    components: {
        TitleModel, MapDemo
    },
    props: ['navData'],
    setup(props, context) {
        const { t } = useI18n()
        const state = reactive({
            advantageList: [],
            patentList: [
                // {name:t('main.robotPatents'),num:'300+'},
                // {name:t('main.invention'),num:'200+'},
                // {name:'PCT',num:'30+'},
                // {name:t('main.application'),num:'60+'},
            ],
            certificateList: [],
            courseBannerList: [],
            addressList: [],
            imgUrl: ldImg(),
            leftIndex: 0,
            timeSetInterval: null,
            ldbanner: null,
            honorBanner: null,
            courseBanner: null,
            companyIntroduce: null,
            companyHonor: null,
            movie: null,
            isplay: false,
            playBagImg: null,
            courseListData: null,
            coordinates: null,
        })
        onMounted(() => {
            load()
            $('body,html').animate({
                scrollTop: 0
            })
        })
        function load() {
            const languageType = window.sessionStorage.getItem('language') || 'zh'
            POST({ "language": languageType == 'zh' ? 0 : 1 }, 'get_about').then((msg) => {
                if (msg.code == 20000) {
                    const { about_us_data, honor_list, course_list, address_list } = msg.data
                    const {
                        banner, company_introduce, company_label, company_video, video_cover, company_honor,
                        honor_banner, course_banner, honor_count
                    } = about_us_data
                    state.ldbanner = ldImg() + banner
                    state.companyIntroduce = editorCss.format(company_introduce)
                    state.advantageList = company_label
                    state.movie = ldImg() + company_video
                    state.playBagImg = ldImg() + video_cover
                    state.companyHonor = company_honor
                    state.certificateList = honor_list
                    state.honorBanner = ldImg() + honor_banner
                    state.courseBanner = ldImg() + course_banner
                    state.courseBannerList = course_list
                    state.addressList = address_list
                    state.coordinates = address_list && address_list.length > 0 ? address_list[0].coordinates.split(',') : ''
                    state.patentList = honor_count
                    courseListData(0)
                } else {
                    alert(msg.code)
                }
            })
        }
        function listWidth() {
            let liWidth = $('.bannerUl').width() / state.courseBannerList.length
            return liWidth
        }
        function courseBoxWidth() {
            if (state.leftIndex == state.courseBannerList.length - 1) {
                state.leftIndex = -1
            }
            state.leftIndex++
            courseListData()
        }
        function bannerRight(type) {
            if (type > 0 && state.leftIndex == state.courseBannerList.length - 1) {
                state.leftIndex = -1
            }
            if (type < 0) {
                if (state.leftIndex == 0) {
                    state.leftIndex = state.courseBannerList.length
                }
                state.leftIndex--
            } else {
                state.leftIndex++
            }
            courseListData()
        }
        function bannerTimeStop() {
            clearInterval(state.timeSetInterval)
        }
        function courseListData() {
            state.courseListData = state.courseBannerList[state.leftIndex]
        }
        function playVideo() {
            state.isplay = !state.isplay
            if (state.isplay) {
                $("#video")[0].play();
                $("#video")[0].addEventListener('ended', function () { //结束
                    state.isplay = false
                }, false);
            } else {
                $("#video")[0].pause();
            }
        }
        function imgBanner(index) {
            state.leftIndex = index
            courseListData()
        }
        function setIntervalFun() {
            state.timeSetInterval = window.setInterval(function () {
                courseBoxWidth()
            }, 4000)
        }
        const refData = toRefs(state)
        return {
            courseBoxWidth, bannerRight, bannerTimeStop, setIntervalFun, playVideo, listWidth, imgBanner,
            ...refData,
        }
    },
    mounted() {
        this.setIntervalFun()
    }
}
</script>
<style scoped>
.ldbanner {
    width: 100%;
    display: block;
}

.ulRight {
    margin-left: 2rem;
}

.contactAddress {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4rem;
}

.hideMargin {
    margin-bottom: 0;
}

.addressContent {
    margin-left: 1.9375rem;
    height: 5rem;
}

.addressTitle {
    color: #0089D2;
    font-size: 1.25rem;
    font-family: 'OPPOSans-M';
}

.addressDetails {
    color: #585858;
    font-size: 1rem;
    margin-top: .75rem;
}

.contactLeftIcon {
    width: 1.375rem;
    height: .2rem;
    background: #0089D2;
}

.contactBox {
    background: #F7F9FA;
}

.contactTabel {
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
    padding-top: 5.06rem;
    margin-top: 3.875rem;
}

.contactUl {
    flex: 1;
}

.contact {
    margin-top: 9.9375rem;
}

.courseContentBox {
    padding: 0 3.25rem;
}

.contentUl {
    height: 13.4375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    background: #fff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, .3)
}

.listTitle {
    color: #0089D2;
    font-size: 3.75rem;
    font-weight: bold;
}

.contentLi {
    margin-left: 8.56rem;
}

.listContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .8125rem;
}

.listIcon {
    width: .8125rem;
    height: 1.3125rem;
}

.listDateils {
    color: #606060;
    margin-left: .625rem;
}

.bannerUl {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.25s ease 0s;
}

.bannerLeft {
    margin-right: 1.25rem;
}

.bannerRight {
    margin-left: 1.25rem;
}

.bannerList {
    height: 3px;
    background: #fff;
    position: relative;
}

.bannerListIcon {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    background-color: #fff;
    /* border: 2px solid rgba(0, 0, 0, 0); */
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: .5s;
    transform: translate(-50%, -50%);
}

.selIcon {
    width: 1.6rem;
    height: 1.6rem;
    background-color: #008AD4!important;
}

.selTxt {
    color: #008AD4!important;
}

.bannerTime {
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -190%);
}

.bannerBox {
    flex: 1;
    padding: 3.875rem 1.25rem;
    overflow: hidden;
}

.courseBannerBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4.375rem;
}

.courseBannerIcon {
    width: 2rem;
    height: 3.8125rem;
    cursor: pointer;
}

.courseTitle {
    color: #fff;
    text-align: center;
    font-size: 1.875rem;
    margin-top: 4.5626rem;
}

.course_i {
    display: block;
    margin: auto;
    margin-top: 1.25rem;
    width: 3.46rem;
    height: 3px;
    background: #fff;
}

.ulList {
    display: flex;
    flex-direction: row;
    margin-top: 3.56rem;
}

.listTxt {
    color: #666666;
}

.list {
    flex: 1;
    color: #0089D2;
    font-family: 'OPPOSans-B';
    font-size: 2.315rem;
    text-align: center;
}

.certificateIcon {
    width: 20.3125rem;
    height: 13.5625rem;
}

.aboutBox {
    margin-top: 5.06rem;
    padding-bottom: 0;
}

.introduceBox {
    margin-top: 2.93rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: row;
}

.introduceLeft {
    flex: 1;
}

.period {
    color: #666666;
    margin-bottom: 1.87rem;
    text-align: justify;
    white-space: pre-wrap;
    line-height: 1.875rem;
}

.introduceRight {
    margin-left: 6.68rem;
    width: 33.93rem;
    height: 36.375rem;
    position: relative;
}

.playBox {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: 100% 100%;
    background-repeat: repeat;
}

.playIcon {
    position: absolute;
    width: 7.1875rem;
    height: 7.1875rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.videoIcon {
    width: 100%;
    height: 100%;
    object-fit: fill;
    display: block;
}

.advantage {
    margin-bottom: 1.43rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.advantageIcon {
    width: .8125rem;
    height: 1.3125rem;
    display: block;
}

.advantageTxt {
    color: #666666;
    font-size: .875rem;
    margin-left: 1.25rem;
}

.honorBox,
.course {
    position: relative;
}

.honorUl {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.ulBox {
    padding-bottom: 0;
    padding-top: 0;
}

.honorContent {
    color: #585858;
    padding-bottom: 0;
    padding-top: 2.68rem;
    font-size: 1.25rem;
    text-align: center;
    line-height: 2.625rem;
    white-space: pre-wrap;
}</style>